import React, { useState, useEffect } from "react";
import { ScrollView, View } from "react-native";
import Tienda from "../components/Tienda";
import { connect } from "react-redux";
import { setConexion } from "../../application/reducers";

const listadoTiendas = ({ navigation, route, conexion, ...props }) => {
  const [listadoTiendas, setListadoTiendas] = useState([]);

  const { tiendas, mode, stock } = route.params;

  useEffect(() => {
    setListadoTiendas(tiendas);
  }, []);

  function abrirStock(tienda: any) {
    props.navigation.navigate("vistaStock", {
      tienda: tienda,
    });
  }

  function onPressTienda(tienda: any) {
    if (stock) {
      abrirStock(tienda);
    } else {
      if (
        mode === "waiter" ||
        (tienda.services !== undefined && tienda.services.length > 0)
      ) {
        if (tienda.services.length == 1) {
          if (
            tienda.services[0].structure != undefined &&
            tienda.services[0].structure.length > 0
          ) {
            abrirServicios(tienda);
          } else {
            tienda.servicioSel = tienda.services[0].key;
            abrirTienda(tienda, tienda.services[0].key);
          }
        } else {
          abrirServicios(tienda);
        }
      } else {
        abrirTienda(tienda, global.usuario.setting.services[0]);
      }
    }
  }
  function navigateToScreen(params: any) {
    switch (mode) {
      case "foodStall":
        navigation.navigate("FoodStallScreen", params);
        break;
      default:
        if (global.pantallaDividida) {
          navigation.navigate("detallesTienda", params);
        } else {
          navigation.navigate("listadoFamilias", params);
        }
    }
  }

  function abrirTienda(tienda: any, servicio: any) {
    let aux = {
      tienda: tienda,
    };
    if (conexion) {
      tienda.getDatosTienda(tienda.gid, servicio).then(function (res: any) {
        if (tienda.products.length > 0) {
          navigateToScreen(aux);
        } else {
          alert("La tienda seleccionada no tiene productos");
        }
      });
    } else {
      if (tienda.products.length > 0) {
        navigateToScreen(aux);
      } else {
        alert("La tienda seleccionada no tiene productos");
      }
    }
  }
  function abrirServicios(tienda: any) {
    let aux = {
      tienda: tienda,
    };
    if (mode === "foodStall") {
      abrirTienda(tienda, tienda.services[0].key);
    } else {
      navigation.navigate("seleccionServicios", aux);
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        style={[
          { paddingTop: 20, backgroundColor: "white" },
          global.pantallaDividida
            ? { height: props.height - 60 }
            : { height: props.height - 120 },
        ]}
        contentContainerStyle={{ paddingBottom: 60, alignItems: "center" }}
        showsVerticalScrollIndicator={false}
      >
        {listadoTiendas.map(function (tienda: any, indice: number) {
          return <Tienda abrirTienda={onPressTienda} tienda={tienda}></Tienda>;
        })}
      </ScrollView>
    </View>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tiendas: state.tiendas.tiendas,
    width: state.dimensions.width,
    height: state.dimensions.height,
    conexion: state.dimensions.conexion,
  };
};

const mapDispatchToProps = {
  setConexion,
};

export default connect(mapStateToProps, mapDispatchToProps)(listadoTiendas);
