import React, { useEffect, useRef } from "react";
import { TouchableOpacity, View, Text } from "react-native";
import {
  setFacturaProductos,
  setFacturaComision,
  setPedidoImprimir,
  setReferenciaTicket,
} from "../../application/reducers";
import ProductoResumenCamarero from "../components/ProductoResumenCamarero";
import {
  BluetoothManager,
  BluetoothEscposPrinter,
} from "react-native-bluetooth-escpos-printer";

import { connect } from "react-redux";
import { captureRef } from "react-native-view-shot";
import myCrypto from "../funcionalidades/myCrypto";

const DetallesPedidoCamarero = (props: any) => {
  var ticketRef = useRef(null);

  function devolverPedido() {
    if (props.route != undefined) {
      props.route.params.eliminarPedidosBBDD([
        props.pedidoSeleccionado.note_id,
      ]);
      props.navigation.goBack();
    } else {
      props.eliminarPedidosBBDD([props.pedidoSeleccionado.note_id]);
    }
  }

  useEffect(() => {
    if (props.route != undefined) {
      props.navigation.setOptions({
        headerTitle: props.pedidoSeleccionado.order_id,
      });
    }
  }, []);

  function reImprimirFacturas() {
    global.usuario
      .obtenerFacturas(props.pedidoSeleccionado.gid)
      .then(async function (res: any) {
        if (res?.data?.data?.length > 0) {
          if (res?.data?.data?.length > 0) {
            for (var i = res?.data?.data?.length - 1; i >= 0; i--) {
              let item = res.data.data[i];
              let factura = item.data;
              factura.ticket_bai_url = item.ticket_bai_url || "";
              if (i == 0) {
                props.setFacturaProductos(factura);
                setTimeout(async function () {
                  await myCrypto.imprimirAdyen(
                    global.ticketRefFacturaProductos
                  );
                }, 1000);
              }
              if (i == 1) {
                props.setFacturaComision(factura);
                setTimeout(async function () {
                  await myCrypto.imprimirAdyen(global.ticketRefFacturaComision);
                }, 1000);
              }
            }
          }
        }
      });
  }
  async function comprobarImpresora() {
    props.setPedidoImprimir(props.pedidoSeleccionado);
    setTimeout(function () {
      if (global.isDatafono) {
        reImprimirFacturas();
      } else {
        global.imprimirTicket(false);
      }
    }, 500);
  }
  function imprimirPedido(address: any) {
    BluetoothManager.connect(address).then(
      async () => {
        const result = await captureRef(ticketRef, {
          result: "base64",
          quality: 1,
          format: "png",
        });
        BluetoothEscposPrinter.setWidth(576);
        BluetoothEscposPrinter.printPic(result, {
          width: 576,
          left: 0,
        });
      },
      (e) => {
        abrirListadoBluetooth();
      }
    );
  }

  function abrirListadoBluetooth() {
    // let aux = { eloy todo remove comment
    //   imprimirPedido: imprimirPedido,
    // };
    // props.navigation.navigate("listadoBluetooth", aux);
  }

  function getHoraToTimestamp(timestamp: any) {
    let fecha = new Date(timestamp * 1000);
    let horas = "0" + fecha.getHours();
    let minutos = "0" + fecha.getMinutes();
    return horas.substr(-2) + ":" + minutos.substr(-2);
  }

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <View style={{ justifyContent: "center", flex: 1, alignItems: "center" }}>
        {props.pedidoSeleccionado != false ? (
          <View style={{ flex: 1, width: "100%" }}>
            <View
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "white",
                padding: 20,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <View style={{ flexDirection: "row", marginBottom: 20 }}>
                  <Text
                    style={{
                      marginRight: 10,
                      fontWeight: "bold",
                      color: "#3AA597",
                    }}
                  >
                    #{props.pedidoSeleccionado.order_number}
                  </Text>
                  <Text>
                    {getHoraToTimestamp(props.pedidoSeleccionado.date)}
                  </Text>
                </View>
              </View>
              {props.pedidoSeleccionado.location != "" ? (
                <View
                  style={{
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Text
                    style={{ fontSize: 12, paddingTop: 4, paddingBottom: 4 }}
                  >
                    Localización: {props.pedidoSeleccionado.location}
                  </Text>
                </View>
              ) : null}
              {props.pedidoSeleccionado.comment != undefined &&
              props.pedidoSeleccionado.comment != "" ? (
                <View
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Text
                    style={{ fontSize: 12, paddingTop: 4, paddingBottom: 4 }}
                  >
                    Comentario: {props.pedidoSeleccionado.comment}
                  </Text>
                </View>
              ) : null}
              {props.pedidoSeleccionado.productos.map(function (item: any) {
                console.log("itreme", item);
                if (!item.producto.oculto) {
                  return (
                    <ProductoResumenCamarero
                      disabled={true}
                      lineaPedido={item}
                    ></ProductoResumenCamarero>
                  );
                }
              })}
              <View
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  height: 1,
                  backgroundColor: "black",
                  width: "100%",
                }}
              ></View>
              <View>
                {props.pedidoSeleccionado.order_lines_commission.map(function (
                  item: any,
                  index: any
                ) {
                  if (item.amount > 0) {
                    console.log("item", item);
                    return (
                      <View
                        style={{
                          marginBottom: 1,
                          backgroundColor: "white",
                          padding: 10,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <View style={{ flex: 1, flexDirection: "row" }}>
                            <Text style={{ fontSize: 16 }}>{item.name}</Text>
                          </View>
                          <Text style={{ fontSize: 16 }}>
                            {(item.amount / 100).toFixed(2)}€
                          </Text>
                        </View>
                      </View>
                    );
                  }
                })}
                {props.pedidoSeleccionado.order_lines_service.map(function (
                  item: any,
                  index: any
                ) {
                  if (item.amount > 0) {
                    console.log("item", item);
                    return (
                      <View
                        style={{
                          marginBottom: 1,
                          backgroundColor: "white",
                          padding: 10,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <View style={{ flex: 1, flexDirection: "row" }}>
                            <Text style={{ fontSize: 16 }}>{item.name}</Text>
                          </View>
                          <Text style={{ fontSize: 16 }}>
                            {(item.amount / 100).toFixed(2)}€
                          </Text>
                        </View>
                      </View>
                    );
                  }
                })}
                {props.pedidoSeleccionado.order_lines_discount.map(function (
                  item: any,
                  index: any
                ) {
                  if (item.amount != undefined && item.amount != null) {
                    if (item.amount < 0) {
                      return (
                        <View
                          style={{
                            marginBottom: 1,
                            backgroundColor: "white",
                            padding: 10,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <View style={{ flex: 1, flexDirection: "row" }}>
                              <Text style={{ fontSize: 16 }}>{item.name}</Text>
                            </View>
                            <Text style={{ fontSize: 16 }}>
                              {(item.amount / 100).toFixed(2)}€
                            </Text>
                          </View>
                        </View>
                      );
                    }
                  }
                })}
                <View
                  style={{
                    marginBottom: 1,
                    backgroundColor: "white",
                    padding: 10,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ flex: 1, flexDirection: "row" }}>
                      <Text style={{ fontSize: 16 }}>TOTAL</Text>
                    </View>
                    <Text style={{ fontSize: 16 }}>
                      {props.pedidoSeleccionado
                        .calcularPrecioPedido()
                        .toFixed(2)}
                      €
                    </Text>
                  </View>
                </View>
                {props.pedidoSeleccionado.payment_method == "dataphone" ? (
                  <Text
                    style={{ fontSize: 12, paddingTop: 4, paddingBottom: 4 }}
                  >
                    Tarjeta
                  </Text>
                ) : (
                  <Text
                    style={{ fontSize: 12, paddingTop: 4, paddingBottom: 4 }}
                  >
                    Efectivo
                  </Text>
                )}
              </View>
            </View>
          </View>
        ) : (
          <Text>Selecciona un pedido para ver sus detalles</Text>
        )}
      </View>
      {props.pedidoSeleccionado != false ? (
        <View
          style={{
            backgroundColor: "white",
            position: "absolute",
            height: 50,
            width: "100%",
            bottom: 0,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <TouchableOpacity
              onPress={comprobarImpresora}
              style={{
                backgroundColor: "#3AA597",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <Text style={{ color: "white" }}>Imprimir ticket</Text>
            </TouchableOpacity>
            <View
              style={{ width: 1, height: "100%", backgroundColor: "white" }}
            ></View>
            {!props.pedidoSeleccionado.sincronizado ? (
              <TouchableOpacity
                onPress={devolverPedido}
                style={{
                  backgroundColor: "#3AA597",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <Text style={{ color: "white" }}>Devolver</Text>
              </TouchableOpacity>
            ) : null}
          </View>
        </View>
      ) : null}
    </View>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tiendas: state.tiendas.tiendas,
    pedidoSeleccionado: state.pedidos.pedidoSeleccionado,
  };
};

const mapDispatchToProps = {
  setPedidoImprimir,
  setReferenciaTicket,
  setFacturaProductos,
  setFacturaComision,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetallesPedidoCamarero);
