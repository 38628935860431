import React from "react";
import { Feather } from "@expo/vector-icons";
import { StyleSheet, View } from "react-native";

// Components
import Divider from "../Divider";
import ActionButton from "./ActionButton";

const AddProduct = ({ onAdd, buttonSize = 30, iconSize = 25 }) => {
  return (
    <View style={styles.container}>
      <Divider height={10} />
      <ActionButton color={"#D2F3C2"} onPress={onAdd} size={buttonSize}>
        <Feather name="plus" size={iconSize} color={"#4AA81E"} />
      </ActionButton>
    </View>
  );
};

export default AddProduct;

const styles = StyleSheet.create({
  container: {
    height: "100%",
    maxWidth: 60,
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
});
