import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import { useTranslation } from "react-i18next";

// Components
import MainButton from "../MainButton";
import CustomInput from "../CustomInput";
import ModalBase from "./ModalBase";
import Divider from "../Divider";

const BackModal = ({ visible, setModal, navigation }) => {
  const [password, setPassword] = useState();
  const [error, setError] = useState(false)
  const { t } = useTranslation();

  useEffect(() => {
    setError(false)
  }, [password])

  const backHandler = () => {
    if (password === "IB5623") {
      navigation.goBack();
      setModal()
    }else{
      setError(true)
    }
  };

  return (
    <ModalBase modalVisible={visible} handleModal={() => setModal()}>
      <View style={styles.inputWrapper}>
        <Image
          style={styles.image}
          source={require("../../../../assets/images/password.png")}
        />
        <Divider height={20} />
        <Text style={styles.text}>{t("backCode.text")}</Text>
        <Divider height={20} />
        <View style={styles.inputContainer}>
          <CustomInput
            multiline
            value={password}
            onChange={setPassword}
            placeholder={t("backCode.placeholder")}
          />
        </View>
        <Divider height={20} />
        {error && (
          <>
            <Text style={styles.error}>{t("backCode.error")}</Text>
            <Divider height={20} />
          </>
        )}
        <View style={styles.buttonWrapper}>
          <MainButton title={t("accept")} onPress={backHandler} />
        </View>
      </View>
    </ModalBase>
  );
};

export default BackModal;

const styles = StyleSheet.create({
  inputWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonWrapper: {
    height: 50,
    flexDirection: "row",
    maxWidth: 200,
    width: "100%",
  },
  image: {
    width: "100%",
    resizeMode: "contain",
    height: 70,
  },
  inputContainer: {
    width: 190,
    height: 45,
  },
  text: {
    fontSize: 18,
    color: "black",
    textAlign: "center",
  },
  error: {
    fontSize: 18,
    color: "red",
    textAlign: "center",
  },
});
