export function getOrder({ tiendas, comment, roomNumber, cashPaid }) {
  let ordersAux: Array<any> = [];
  let locationsTiendas = "";
  let contadorLocalizaciones = 0;
  tiendas.map(function (item: any) {
    let order_lines: Array<any> = [];
    if (item.locationsSel != undefined) {
      item.locationsSel.map(function (loc: any, index: any) {
        if (contadorLocalizaciones == index) {
          contadorLocalizaciones++;
          locationsTiendas = locationsTiendas + " " + loc.select;
        }
      });
    }

    let precioTotalProductosRestantes = 0;
    item.productos.map(function (producto: any) {
      precioTotalProductosRestantes =
        precioTotalProductosRestantes +
        (producto.precioTotalProducto || producto.unit_price / 100) *
          producto.cantidad;
      let delete_ingredients = producto.getArrayIngredientesSin();
      let variants = producto.getArrayVariantes();
      let menu_selection = producto.getMenuSelection();
      let orderLine = {
        id_product: producto.gid,
        quantity: producto.cantidad,
        id_point_sale: producto.fk_point_sale,
        fk_point_sale: producto.fk_point_sale,
        fk_product: producto.gid,
        variants: variants,
        groups: variants,
        ingredients_removed: delete_ingredients,
        delete_ingredients: delete_ingredients,
        menu_selection: menu_selection,
        amount: 0,
        cashPaid: cashPaid,
        name: producto.name,
        unit_price: 0,
        exclusive_order: producto.exclusive_order,
        comisionGestionProducto: producto.comisionGestionProducto,
        comisionGopickProducto: producto.comisionGopickProducto,
      };

      order_lines.push(orderLine);
    });

    let serviceSel = "pick_up";
    let date = Math.floor(new Date().getTime() / 1000);
    let locationTienda = "";
    let gidLocation = null;
    if (item.locationsSel != undefined && item.locationsSel.length > 0) {
      item.locationsSel.map(function (loc: any) {
        locationTienda = locationTienda + " " + loc.select;
      });
      gidLocation =
        item.locationsSel[item.locationsSel.length - 1].gid_location || null;
    }
    if (item.deliveryAddress != undefined && item.deliveryAddress != "") {
      locationTienda = item.deliveryAddress;
    }

    let order_lines_exclusive = [];
    let order_lines_restantes = [];

    order_lines.map(function (item: any) {
      if (item.exclusive_order == 1) {
        let cantidad = item.quantity;
        item.quantity = 1;
        for (let i = 0; i < cantidad; i++) {
          order_lines_exclusive.push(item);
        }
      } else {
        order_lines_restantes.push(item);
      }
    });
    if (order_lines_exclusive.length > 0) {
      order_lines_exclusive.map(function (order_line: any, index: any) {
        let precioTotalDelProducto = 0;
        precioTotalProductosRestantes = 0;

        let order_lines_credit_consumedProducto = [];
        let timeNow = new Date().getTime().toString();
        let gid = timeNow.substr(-9);
        let localOrderId = gid;
        let orderAux = {
          localOrderId: localOrderId,
          gid: gid,
          id_shop: item.gid,
          id_event: item.fk_event,
          shop_name: item.name,
          service: serviceSel || "pick_up",
          date: date,
          order_lines: [order_line],
          order_date: new Date(),
          amountLocal: 0,
          order_lines_commission: [],
          order_lines_discount: [],
          order_lines_credit_consumed: order_lines_credit_consumedProducto,
          location: locationTienda,
          gid_location: gidLocation,
        };
        ordersAux.push(orderAux);
      });
    }
    if (order_lines_restantes.length > 0) {
      let timeNow = new Date().getTime().toString();
      let gid = timeNow.substr(-9);
      let localOrderId = gid;
      let orderAux = {
        localOrderId: localOrderId,
        gid: gid,
        id_shop: item.gid,
        id_event: item.fk_event,
        shop_name: item.name,
        service: serviceSel || "pick_up",
        date: date,
        order_date: new Date(),
        amountLocal: 0,
        order_lines: order_lines_restantes,
        location: locationTienda,
        gid_location: gidLocation,
      };
      ordersAux.push(orderAux);
    }
  });

  let carritoAux = {
    id_user: global.usuario.gid,
    payment_method: "cash",
    comment: comment + " - Habitacion " + roomNumber,
    customer_email: "",
    location: locationsTiendas,
    id_currency: 1,
    cashPaid: cashPaid,
    delivery_address: "",
    orders: ordersAux,
  };
  return carritoAux;
}
