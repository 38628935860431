import Familia from './Familia'

import tiendaCalls from '../funcionalidades/tiendaCalls'
export default class Tienda{
  gid= 10
  active= 1
  user_id= "465"
  /*created= 1644839654
  changed= 1644839824*/
  name= "Tienda fest1"
  description= "Descripción corta de la tienda"
  image= "https://gopick-app-drupal-images.s3.eu-west-3.amazonaws.com/festival_0.jpg"
  pick_up_time= 1
  pick_up_date= 0
  time_ranges= 15
  just_menu= 0
  wait_time= "10 min"
  cif= null
  /*fiscal_name= null
  fiscal_address= null*/
  type_gopick_commission= null
  gopick_commission= 5
  type_waiter_commission= "percentage"
  waiter_commission= null
  alerts= [
    {
      "name": "Alert 1",
      "active": 1,
      "message": "<p>Mensaje de prueba al entrar a la tienda</p>\r\n",
      "show_in": "store"
    }
  ]
  fk_event= 6

  payment_method= []
  divide_orders_point_sale= 0
  ticketing= 0
  previous_time_scheduled= 15
  products=[]
  schedule_slots=[]
  services=[]
  schedule=[]
  rangosHorarios=[]
  barras=[]
  localizaciones=[]
  constructor(obj:any) {

    this.gid=obj.gid || 10
    this.active=obj.active
    this.user_id=obj.user_id || ""
    
    this.name=obj.name || ""
    this.description=obj.description || ""
    this.image=obj.image || "https://gopick-app-drupal-images.s3.eu-west-3.amazonaws.com/festival_0.jpg"
    this.pick_up_time=obj.pick_up_time || 1
    this.pick_up_date=obj.pick_up_date || 0
    this.time_ranges=obj.time_ranges || 15
    this.just_menu=obj.just_menu || 0
    this.wait_time=obj.waitTime || ""
    this.cif=obj.cif || null
    

    if(!global.tpv){
      this.type_waiter_commission=obj.type_waiter_commission || null
      this.waiter_commission=obj.waiter_commission || null
    }
    this.alerts=obj.alerts || []
    this.fk_event=obj.fk_event || 0
    
    this.payment_method=obj.payment_method || []
    this.divide_orders_point_sale=obj.divide_orders_point_sale || 0
    this.ticketing=obj.ticketing || 0
    this.previous_time_scheduled=obj.previous_time_scheduled || 0
    this.products=obj.products ||[]
    this.schedule_slots= obj.schedule_slots || []
    this.services= obj.services || []
    this.schedule= obj.schedule || []


    this.rangosHorarios=obj.rangosHorarios || []

    this.barras = obj.barras || [
        {
          id:'1',
          nombre:'barra 1',
          pedidos:[]    
        },
        {
          id:'2',
          nombre:'barra 2',
          pedidos:[]
        }
      ]
    
    if(obj.locations!=null && obj.locations!=undefined){
      this.cargarLocations(obj.locations)
    }
    
    this.cargarFamilias(this.products)
 
    if(this.schedule_slots.length>0){
      this.cargarHoras(this.schedule_slots)
    }
    console.log("this!!!!!!!!!",this)
  }
  cargarHoras(slots:any){
    let thisTienda = this
    let rangosHorariosAux={}
    let rangosHorarios=[]

    
    slots.map(function(hora:any,index:any){
      let horaAux = hora.time.split(':')[0]
      if(rangosHorariosAux[horaAux]!=undefined){
        let existe = -1
        rangosHorariosAux[horaAux].map(function(horaAux:any,index:any){
          if(hora.time==horaAux.time){
            existe=index;
          }
        })
        if(existe==-1){
          rangosHorariosAux[horaAux].push(hora)
        }
      }else{
        rangosHorariosAux[horaAux]=[hora]
      }
    })
    Object.keys(rangosHorariosAux).map(function(key:any,index:any){
      if(index==0){
        rangosHorariosAux[key][0].time='Lo antes posible'
      }
      rangosHorarios.push(rangosHorariosAux[key])
    })
    thisTienda.rangosHorarios=rangosHorarios
  }
  cargarLocations(localizaciones:any){
    let thisTienda = this
    thisTienda.services.map(function(servicio:any,index:any){
      if(servicio.levels_locations!='not_show'){

       
          servicio.structure=localizaciones
       
        
        
      }
      
    })
    thisTienda.localizaciones=localizaciones
  }
  async getDatosTienda(idTienda:any,servicio:any,localizacion:any){
      //return await usuarioCalls.login(this.email, this.pass);
      var t = this;
      return new Promise(function(resolve, reject) {
         tiendaCalls.getDatosTienda(idTienda,servicio,localizacion).then(function(result:any){
            
             console.log("result",result)
             if(!global.tpv){
               t.type_waiter_commission=result.data[0].type_waiter_commission || null
               t.waiter_commission=result.data[0].waiter_commission/100 || null
             }
             t.divide_orders_point_sale=  result.data[0].divide_orders_point_sale || 0
             t.cargarFamilias(result.data[0].products)
             if(result.data[0].schedule_slots!=undefined && result.data[0].schedule_slots.length>0){
              t.cargarHoras(result.data[0].schedule_slots)
             }
            resolve(t.gid);
         }).catch((error:any) => {
            console.error('Error login -> '+error);
            reject();
         });
      });
  }
  cargarFamilias(familias:any){

    let thisTienda = this
    
    thisTienda.products=[]
    familias.map(function(familia:any,indice:number) {
      thisTienda.products.push(new Familia(familia))
    });
  }
  
  guardarTiendaBBDD(){
    let t = this
    global.db.transaction((tx:any) => {
      tx.executeSql(
        "CREATE TABLE IF NOT EXISTS tiendas ("+
          "gid TEXT PRIMARY KEY NOT NULL,"+
          "tiendaParams TEXT"+
        ");"
      );
    },function(err:any){console.log("err",err)});
    global.db.transaction((tx:any) => {
      tx.executeSql(
        "INSERT OR REPLACE INTO tiendas("+
        "gid ,"+
        "tiendaParams"+
        ") VALUES ('"+
        t.gid+"', '"+
        JSON.stringify(t)+
        "');"
      );
    },function(err:any){console.log("err",err)});
  }
  
    
      
  
} 