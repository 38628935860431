let arrayInit:Array<any>=[]
export default class Producto{

  variantesSel=[];
  stock ='';
  ingredientesSinSel=[];
  platos_elegir=[]; 
  platosSel=[];
  agotado=false
  estado=null
  incremento = 0;
  cantidad = 1
 precio=0;

  gid=22
  fast=0
  name="Margarita Nachos"
  image=""
  active=1
  amount=850
  fk_shop=10
  fk_point_sale=0
  sold_out=0
  variants=[{}]
  only_menu=0
  admin_nombre="Margarita Nachos"
  ingredients=[
    "Pulled Pork",
    "Jalapeños",
    "Pepinillo dulce",
    "Crema agria",
    "Totopos",
    "Guacamole",
    "Salsa queso cheddar"
  ]
  product_type="individual"
  only_legal_age=0
  exclusive_order=0
  not_pay_service=0
  long_description="<p>Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga Descripción larga</p>\r\n"
  sort_description="Descripción corta de margarita nachos"
  ingredients_group_nombre="Desmarca si no quieres algún ingrediente"
  plates=[]
  menu_lines=[]
  groups=[]
  fk_product=0
  state=''
  allergens=[]
  unit_price=0
  oculto=false
  marcado=false
  constructor(obj:any) {


    this.variantesSel=obj.variantesSel || [];
    this.stock = obj.stock || '';
    if(obj.stock ===0){
      this.stock=0
    }
    this.ingredientesSinSel=obj.ingredientesSinSel || [];
    this.platos_elegir=obj.platos_elegir || []; 
    this.platosSel=obj.platosSel || [];
    this.agotado=false
    this.estado=obj.estado || null
    this.incremento = parseFloat(obj.incremento) || 0;
    this.precio = obj.precio || 0 
    this.cantidad = obj.cantidad || 1

    this.gid= obj.gid || 0
    this.fast= obj.fast || 0
    this.name= obj.name || ""
    this.image= obj.image || ""
    this.active= obj.active
    this.amount= (parseFloat(obj.amount)/100) || 0
    this.fk_shop= obj.fk_shop || 0
    this.fk_product=obj.fk_product || 0
    this.sold_out= obj.sold_out || 0
    this.state=obj.state || ''
    let variantesAux
    if(obj.variants!=undefined){
      if(obj.variants[0]!=undefined){
        if(Object.keys(obj.variants[0]).length!=0){
          variantesAux=obj.variants
        }
      }
    }else{
      variantesAux=obj.groupVariants
    }

    this.fk_point_sale = obj.fk_point_sale || 0
    this.variants= variantesAux || []
    this.only_menu= obj.only_menu || 0
    this.admin_nombre= obj.admin_nombre || ""
    this.ingredients= obj.ingredients || []
    this.product_type= obj.product_type || "individual"
    this.only_legal_age= obj.only_legal_age || 0
    this.exclusive_order= obj.exclusive_order || 0
    this.not_pay_service= obj.not_pay_service || 0
    this.long_description= obj.long_description || ""
    this.sort_description= obj.sort_description || ""
    this.ingredients_group_nombre= obj.ingredients_group_name || "Ingredientes"
    this.plates = obj.plates || []
    this.menu_lines = obj.menu_lines ||[]
    if(obj.orderLineMenus!=undefined){
      this.menu_lines=obj.orderLineMenus ||[]
    }
    this.groups= obj.groups ||[]
    this.allergens = obj.allergens || 0
    if(obj.unit_price!=undefined && obj.unit_price!=null){
      this.unit_price=(parseFloat(obj.unit_price)/100)
    }
    if(this.variantesSel.length==0){
      this.cargarVariantes()
    }
    this.oculto= obj.oculto || false
    this.marcado= obj.marcado || false
    //this.cargarTipo()
    this.cargarPlatosElegir()
    this.agruparMenus()

  }
  comprobarVariantes(variantes1:any,variantes2:any){
    let resultado= false
    if(variantes1==null && variantes2==null){
      resultado=true
    }
    if(variantes1!=null &&variantes2!=null && variantes1.length==variantes2.length){
      let arraygid1 = []
      let arraygid2 =[]
      variantes1.map(function(item:any,index:number){
        item.variants.map(function(varianteSel,index:number){
          arraygid1.push(varianteSel.fk_product_variant)
        })
        arraygid1.push(item.fk_product_group_variant)
      })
      variantes2.map(function(item:any,index:number){
        item.variants.map(function(varianteSel,index:number){
          arraygid2.push(varianteSel.fk_product_variant)
        })
        arraygid2.push(item.fk_product_group_variant)
      })
      if(arraygid1.length === arraygid2.length && arraygid1.every((v:any, i:any) => v === arraygid2[i])){
        resultado=true
      }
    }
    return resultado;
  }
  agruparMenus(){
    let thisProducto=this
    let menuLinesAgrupados=[]

    if (thisProducto.menu_lines) {
      thisProducto.menu_lines.map(function(menu_line:any,index:number) {
          let existe = -1
          menuLinesAgrupados.map(function(item:any,index:number){
            // se esta comprobando que los platos del menu sean completamente iguales
            // primero se comprueba que el producto sea el mismo, luego las variantes y luego los ingredientes.
            if(JSON.stringify(item.productId)==JSON.stringify(menu_line.productId)){
              if(JSON.stringify(item.product)==JSON.stringify(menu_line.product)){
                if(thisProducto.comprobarVariantes(item.variants,menu_line.variants)){
                  if(JSON.stringify(item.ingredients_removed)==JSON.stringify(menu_line.ingredients_removed)){
                     existe=index
                  }
                }
              }
          }
          })
          if(existe!=-1){
            menuLinesAgrupados[existe].quantity++
          }else{
            menu_line.quantity=1
            menuLinesAgrupados.push(menu_line)
          }
      })

    }
    thisProducto.menu_lines=menuLinesAgrupados
  }
  cargarVariantes(){
    let thisProducto=this 
    thisProducto.variantesSel=[]

    thisProducto.variants.map(function(variante:any,indice:number) {
      let opcionesAux:Array<any>=[]
      let variantesOpciones=[]
      if(variante.options!=undefined){
        variantesOpciones=variante.options
      }
      if(variante.variants!=undefined){
        variantesOpciones=variante.variants
      }
      if(variante.orderLineGroupVariants!=undefined){
          variantesOpciones=variante.orderLineGroupVariants
        }
      if(variantesOpciones!=undefined){
        variantesOpciones.map(function(opcion:any,indice:number) {
          let auxOpcionSel = JSON.parse(JSON.stringify(opcion))
          /*if(variante.tipo!='check' && indice==0){
            auxOpcionSel.valor = true
          }else{
            auxOpcionSel.valor = false
          }*/
          auxOpcionSel.valor=opcion.default
          auxOpcionSel.nombre=opcion.name
          auxOpcionSel.gid=opcion.gid
          let incrementoAux = opcion.increment
          if(incrementoAux!=undefined){
            incrementoAux=parseFloat(incrementoAux)/100
          }
          auxOpcionSel.incremento=incrementoAux || 0
          auxOpcionSel.cantidad = 1
          opcionesAux.push(auxOpcionSel)

          
        });
        let auxVarianteSel = JSON.parse(JSON.stringify(variante))
        auxVarianteSel.opciones=opcionesAux
        auxVarianteSel.numSel=0
        auxVarianteSel.num_max=variante.max_select || true
        if(variante.allow_quantity==0){
          auxVarianteSel.permitir_cantidad=false
        }else{
          auxVarianteSel.permitir_cantidad=true
        }
        
        auxVarianteSel.id=variante.gid
        auxVarianteSel.tipo=variante.type
        thisProducto.variantesSel.push(auxVarianteSel)
      }
    });

  }
  /*cargarTipo(){
    let thisProducto=this 
    if(thisProducto.product_type!='Menú' && thisProducto.product_type!='menu'){
      if(thisProducto.variants.length>0 || thisProducto.ingredients.length>0){
        thisProducto.product_type ='variantes'
      }
    }
  }*/
  cargarPlatosElegir(){
    let thisProducto=this
    let platos_elegir=[]

    thisProducto.plates.map(function(plato:any,indice:number) {
      let productosElegir:Array<any>=[]
      plato.products.map(function(producto:any,indice:number) {
        let productoAux;

          let incrementoAux = producto.increment
          if(incrementoAux!=undefined){
            incrementoAux=parseFloat(incrementoAux)/100
          }
          producto.precio = incrementoAux
          productoAux =new Producto(producto)
        
       
        productosElegir.push(productoAux)
      });
      plato.productos= productosElegir
      platos_elegir.push(plato)
    });
   thisProducto.platos_elegir=platos_elegir 
  }
  calcularPrecioProducto(){
    let producto=this 
    let precioTotal = producto.amount
    if(producto.unit_price!=0){
      precioTotal = producto.unit_price
    }
   
    if(producto.variantesSel!=undefined){
      producto.variantesSel.map(function(grupo:any,index:number) {
          grupo.opciones.map(function(opcion:any,index:number) {
            if(opcion.valor){
              precioTotal= precioTotal + (opcion.incremento*opcion.cantidad)
            }
          }) 

      })
    }
    if(producto.platosSel!=undefined){
      producto.platosSel.map(function(grupo:any,index:number) {
          grupo.productos.map(function(producto:any,index:number) {
            let precioProducto = producto.precio
            producto.variantesSel.map(function(grupo:any,index:number) {
                grupo.opciones.map(function(opcion:any,index:number) {
                  if(opcion.valor){
                    precioProducto= precioProducto + (opcion.incremento*opcion.cantidad)
                  }
                }) 

            })
            precioTotal= precioTotal + precioProducto
          })
      })
    }
    return precioTotal
  }
  getArrayIngredientesSin(){
    let thisProducto=this
    let delete_ingredients=[]
    thisProducto.ingredientesSinSel.map(function(ingrediente:any,i:number) {
      delete_ingredients.push(ingrediente.nombre)
    })
    return delete_ingredients
  }
  getArrayVariantes(){
    let thisProducto=this
    let variants=[]
    thisProducto.variantesSel.map(function(variante:any,i:number) {
      let opcionesAux=[]
      variante.opciones.map(function(opcion:any,i:number) {
       if(opcion.valor){
         opcionesAux.push({
           gid:opcion.gid,
           quantity:opcion.cantidad,
           name:opcion.name,
           amount:opcion.incremento*100
         })
       }
      })
      if(opcionesAux.length>0){
        variants.push({
          id_group_variants:variante.gid,
          variants:opcionesAux,
          name:variante.name
        })
      }
      
    })
    return variants;
  }
  getMenuSelection(){
    let thisProducto=this
    let menu_selection=[]
    thisProducto.platosSel.map(function(plato:any,i:number) {
      let platosAux=[]
      menu_selection.push({
        id_menu_type_plate:plato.gid,
        id_product:plato.productos[0].gid,
        quantity:plato.productos[0].cantidad,
        name:plato.productos[0].name,
        variants:plato.productos[0].getArrayVariantes(),
        delete_ingredients:plato.productos[0].getArrayIngredientesSin(),
        ingredients_removed:plato.productos[0].getArrayIngredientesSin(),
        product:{
          name:plato.productos[0].name,
          increment: plato.productos[0].precio*100
        }
      })
    })
    return menu_selection;
  }
  calcularPrecioProductoTicket(){

    let producto=this 

    let precioTotal = producto.amount
    if(producto.unit_price!=0){
      precioTotal = producto.unit_price
    }

    if(producto.groups!=undefined){
      producto.groups.map(function(grupo:any,index:number) {
        let variantesOpciones=[]
        if(grupo.variants!=undefined){
          variantesOpciones=grupo.variants
        }
        if(grupo.orderLineGroupVariants!=undefined){
          variantesOpciones=grupo.orderLineGroupVariants
        }
          variantesOpciones.map(function(opcion:any,index:number) {
            if(opcion.amount!=undefined && opcion.amount!=null){
               precioTotal= precioTotal + (opcion.amount/100*opcion.quantity)
            }
          }) 
      })
    }
    if(producto.menu_lines!=undefined){
      producto.menu_lines.map(function(producto:any,index:number) {
            let precioProducto = 0
            if(producto.product?.increment>0){
              precioProducto = producto.product.increment/100
            }
            if(producto.variants!=undefined && producto.variants!=null){
              producto.variants.map(function(grupo:any,index:number) {
                let variantesOpciones=[]
                if(grupo.variants!=undefined){
                  variantesOpciones=grupo.variants
                }
                if(grupo.orderLineMenuGroupVariants!=undefined){
                  variantesOpciones=grupo.orderLineMenuGroupVariants
                }
                    variantesOpciones.map(function(opcion:any,index:number) {
                    if(opcion.amount!=undefined && opcion.amount!=null){
                       precioProducto= precioProducto + (opcion.amount/100*opcion.quantity)
                    }
                  }) 

              })
            }
            precioTotal= precioTotal + precioProducto

      })
    }
    return precioTotal
  }
} 