import { Image, StyleSheet } from "react-native";

export const getFlagIcon = (lang: string) => {
  switch (lang) {
    case "en":
      return (
        <Image
          style={styles.flagContainer}
          source={require("../../../../../assets/flags/flag_en.png")}
        />
      );

    case "es":
      return (
        <Image
          style={styles.flagContainer}
          source={require("../../../../../assets/flags/flag_es.png")}
        />
      );
    case "ge":
      return (
        <Image
          style={styles.flagContainer}
          source={require("../../../../../assets/flags/flag_ge.png")}
        />
      );
  }
};

const styles = StyleSheet.create({
  flagContainer: {
    width: 26,
    height: 26,
    borderRadius: 20,
    resizeMode: "cover",
    borderWidth: 1,
    borderColor: "grey",
  },
});
