import React, { useState, useEffect } from 'react';
import {TouchableOpacity,Text,View,Platform,ScrollView,Alert,ActivityIndicator} from "react-native";
import { Switch } from 'react-native-switch';
import { SearchBar } from 'react-native-elements';
import { connect } from "react-redux";
import {setFooterVisible,setPedidoImprimir,setTiendas,setConexion,setPedidos,setTiendasCarrito,setPedidosPerdidos,setSocketConectado,setCargandoFiltros } from "../../application/reducers";
import {Octicons,Entypo,MaterialIcons,AntDesign,EvilIcons,Ionicons ,FontAwesome,FontAwesome5,Feather } from '@expo/vector-icons';
import { createStyles, maxWidth} from 'react-native-media-queries';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Tienda from "../objetos/Tienda"
import NetInfo from '@react-native-community/netinfo';
const VistaMenuInterior = (props:any) => {

   const [switchValue, setSwitchValue] = useState(true);
   const [existeNoSinc, setExisteNoSinc] = useState(false);
   const [puntosDeVenta, setPuntosDeVenta] = useState({});

   function abrirFiltros(){
    props.setCargandoFiltros(true)
    setTimeout(function(){
      props.navigation.navigate('filtros',
        { 
          filtrosSel:global.usuario.filtros || {},
          onGoBack: (filtros:any) => cambiarFiltros(filtros),
        }
      );
    },0)
    
    
    }
    function guardarConfiguracionBBDD(filtros:any){
      console.log('--guardarConfiguracionBBDD--')
      global.db.transaction((tx:any) => {
         tx.executeSql(
           "CREATE TABLE IF NOT EXISTS userFiltros ("+
           "userGid TEXT"+","+
            "filtros TEXT"+
           ");"
         );
      },function(err:any){console.log("err creating",err)});

      global.db.transaction((tx:any) => {
        tx.executeSql(
          "DELETE from userFiltros WHERE userGid = "+global.usuario.gid+";"
        );
     },function(err:any){console.log("err deleting",err)});

      global.db.transaction((tx:any) => {
         tx.executeSql(
           "INSERT OR REPLACE INTO userFiltros("+
           "userGid"+","+
           "filtros"+
           ") VALUES ('"+
           global.usuario.gid+"','"+
           JSON.stringify(filtros)+
           "');"
         );
      },function(err:any){console.log("err inserting",err)});
   }
    function cambiarFiltros(filtros:any){
      AsyncStorage.setItem('@filtrosAnteriores',  JSON.stringify(filtros));
      guardarConfiguracionBBDD(JSON.stringify(filtros))
      global.usuario.filtros=JSON.parse(JSON.stringify(filtros))
      global.filtrosAnteriores =filtros
    }
   function abrirEscaner(){
      props.navigation.navigate('vistaEscaner');
    }
     function abrirMenu(){
        props.navigation.reset({
          index:0,
          routes:[{name:'menu'}]
        })
    }
    
    useEffect(() => {
      props.setFooterVisible(false)
      props.navigation.addListener('beforeRemove', () => {
        props.setFooterVisible(true)
      });
      global.usuario.cargarPedidosDatafonoBBDD().then(function(resultado:any){
        if(resultado.pedidosNoSinc.length>0){
          setExisteNoSinc(true)
        }
      })
    }, []);

    useEffect(() => {
      setPuntosDeVenta(props.stock)
      
    }, [props.stock]);
    function abrirStock(tienda:any){
      props.navigation.navigate('vistaStock',
        { 
          onGoBack: (stock:any) => cambiarStock(stock),
          puntosDeVenta:puntosDeVenta,
          filtrosSel:global.usuario.filtros,
          tienda:tienda
        }
      );
    }

    function abrirTiendasStock(tiendasAux:any){
      global.usuario.cargarTiendas().then(function(res:any){
        let tiendasAux:Array<any>=[]
        res.map(function(tienda:any,indice:number) {
          tiendasAux.push(new Tienda(tienda))
        });
        if(tiendasAux.length==1){
          abrirStock(tiendasAux[0])
        }else{
          let aux={
            'stock':true,
            "tiendas":tiendasAux
          }
          props.navigation.navigate('listadoTiendas',aux)
        }
      })
      
    }
    function cambiarStock(stock:any){
      setPuntosDeVenta(stock)
    }
   
   
    function abrirTiendas(){
      global.tpv=true
      if(props.conexion){
        global.usuario.cargarTiendas().then(function(res:any){
          let tiendasAux:Array<any>=[]
          res.map(function(tienda:any,indice:number) {
            tiendasAux.push(new Tienda(tienda))
          });
          props.setTiendas(tiendasAux)
          comprobarTiendas(tiendasAux)
        })
      }else{
        comprobarTiendas(props.tiendas)
      }
    }
    function comprobarTiendas(tiendasAux:any){
      if(tiendasAux.length==1){
        abrirTienda(tiendasAux[0])
      }else{
        let aux={
          "tpv":true,
          "tiendas":tiendasAux
        }
        props.navigation.navigate('listadoTiendas',aux)
      }
    }
    function abrirTienda(tienda:any){
      let aux={
        tienda:tienda
      }
      if(props.conexion){
        tienda.getDatosTienda(tienda.gid,'pick_up').then(function(res:any){
          if(tienda.products.length>0){
            props.navigation.navigate('detallesTienda',aux)
          }else{
            alert("La tienda seleccionada no tiene productos")
          }
        })
      }else{
        if(tienda.products.length>0){
            props.navigation.navigate('detallesTienda',aux)
          }else{
            alert("La tienda seleccionada no tiene productos")
          }
      }
    }
   
    function abrirVistaCerrarTiendas(){
      
      
      global.usuario.cargarTiendasStock().then(function(res:any){
        let tiendasAux:Array<any>=[]
        res.map(function(tienda:any,indice:number) {
          let tiendaAux = new Tienda(tienda)
          console.log('tiendaAux',tiendaAux)
          tiendasAux.push(tiendaAux)
        });

        let aux={
          tiendas:tiendasAux
        }
        
        props.navigation.navigate('vistaCerrarTiendas',aux)
        
      })
      
    }
    function goBack(){
       props.navigation.goBack();
    }
    function abrirConfiguracionImpresoras(){

      // if(Platform.OS!='web'){ eloy todo remove comment
      //   let aux={
      //     impresoraSeleccionada: (address:any) => {},
      //   }
      //   props.navigation.navigate('listadoBluetooth',aux);
      // }
    }
    function borrarRedux(){
      props.setPedidoImprimir(null)
      props.setTiendas([])
      props.setConexion(true)
      props.setPedidos([])
      props.setTiendasCarrito([])
    }
    function reiniciarApp(){
      props.navigation.reset({
          index:0,
          routes:[{name:'menu'}]
      })
      recargarPedidos()
    }
    function cerrarSesion(){
      AsyncStorage.setItem('@email_usuario','');
      AsyncStorage.setItem('@pass_usuario', '');
       props.navigation.reset({
          index:0,
          routes:[{name:'login'}]
      })

      global.socket.socket.off("orders:recent")
      global.socket.socket.off("orders:createPaid:result")
      global.socket.socket.offAny();
      global.socket.socket.disconnect()
      global.socket.socketProductos.disconnect()
      global.socket=undefined
      borrarRedux()
    }
    function updateSearch(search_aux:any){

      if(search_aux.length > 0){
        var search = search_aux.toUpperCase();
        const filtro = props.pedidosFiltrados.filter(item => {
          var cadena = item.order_number
          return cadena.toUpperCase().indexOf(search) > -1;
        });
        props.setPedidosBuscador(filtro)
        props.setSearch(search_aux);
      }else{
        props.setPedidosBuscador(props.pedidosFiltrados)
        props.setSearch('');
      }
    }
    function cambiarConexion(){
      if(props.conexion){
        props.setConexion(false)
      }else{
        NetInfo.fetch().then(state => {
          if(state.isConnected){
             props.setConexion(true)
          }else{
            alert("No hay acceso a internet")
          }
        });
      }
    }
    function recargarPedidos(){

        props.setSocketConectado(true)
        props.setPedidosPerdidos(false)
        global.socket.socket.emit("orders:read",{})
      
    }

    function completarPedidos(){
      let ordersIds=[]
      console.log("completarPedidos",props.pedidosFiltrados)
      if(props.pedidosFiltrados){
        props.pedidosFiltrados.map(function(pedido:any,index:number){
          ordersIds.push(pedido.gid)
        })
      }
      
      if(ordersIds.length>0){
        global.usuario.completarPedidos(ordersIds,9)
      }
      
    }
    function finalizarTurno(){
      if(!existeNoSinc){
        AsyncStorage.setItem('@ultimoCierre', (new Date().getTime()/1000).toString());
        completarPedidos()
        props.navigation.reset({
          index:0,
          routes:[{name:'menu'}]
        })
      }else{
        alert("Aún quedán pedidos sin sincronizar!")
      }
    }
    function cerrarTurno(){
      if(Platform.OS === 'web'){
          finalizarTurno()
      }else{
        Alert.alert(
          '',
          '¿Desea finalizar el turno?',
          [
            {
              text: 'Finalizar turno',
              onPress: () => {
                finalizarTurno()
              },
            },
            {
              text: 'Cancelar',
              onPress: () => {console.log('Cancel Pressed')},
              style: 'cancel'
            },
          ],
          { cancelable: true }
        );
      }
     
    }
    return(
            <View style={{backgroundColor:'white',flex:1}}>
                  <View style={{backgroundColor:'white',padding:10,borderColor:'#333333',borderBottomWidth:1,flexDirection:'row',alignItems:'center'}}>
                    <TouchableOpacity style={{paddingRight:10}} onPress={goBack}>
                      <AntDesign name="left" size={24} color="black" />
                    </TouchableOpacity>
                    <View style={{}}>
                      <Text style={{}}>
                        Usuario
                      </Text>
                      <Text style={{}}>
                        {global.usuario.email}
                      </Text>
                    </View>
                  </View>
                  <ScrollView style={{backgroundColor:'white',padding:10,flex:1}}>
                      <TouchableOpacity style={{flexDirection:'row',marginTop:10,alignItems:'center',padding:10,width:'100%'}} onPress={abrirMenu}>
                        <Feather name="home" size={25} color="black" />
                        <Text style={{paddingLeft:5}}>
                          Home
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity style={{flexDirection:'row',marginTop:10,alignItems:'center',padding:10,width:'100%'}} onPress={abrirVistaCerrarTiendas}>
                        <Entypo name="shop" size={25} color="black" />
                        <Text style={{paddingLeft:5}}>
                          Tiendas
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity style={{flexDirection:'row',marginTop:10,alignItems:'center',padding:10,width:'100%'}} onPress={abrirTiendasStock}>
                        <Feather name="box" size={25} color="black" />
                        <Text style={{paddingLeft:5}}>
                          Stock
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity style={{flexDirection:'row',marginTop:10,alignItems:'center',padding:10,width:'100%'}} onPress={abrirFiltros}>
                        {props.cargandoFiltros?(
                          <View style={{flexDirection:'row',justifyContent:'space-between',width:'100%'}}>
                           <View style={{flexDirection:'row'}}>
                            <AntDesign name="filter" size={25} color="black" />
                              <Text style={{paddingLeft:5}}>
                                Filtros
                              </Text>
                            </View>
                            <ActivityIndicator color='black' />
                          </View>
                          ):(
                          <View  style={{flexDirection:'row',width:'100%'}}>
                              <AntDesign name="filter" size={25} color="black" />
                            <Text style={{paddingLeft:5}}>
                              Filtros
                            </Text>
                          </View>)
                        }
                        
                      </TouchableOpacity>
                      <TouchableOpacity style={{flexDirection:'row',marginTop:10,alignItems:'center',padding:10,width:'100%'}} onPress={abrirConfiguracionImpresoras}>
                        <Feather name="printer" size={25} color="black" />
                        <Text style={{paddingLeft:5}}>
                          Listado de impresoras
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity style={{flexDirection:'row',marginTop:10,alignItems:'center',padding:10,width:'100%'}} onPress={cerrarTurno}>
                        <Ionicons name="checkmark-circle-outline" size={24} color="black" />
                        <Text style={{paddingLeft:5}}>
                          Finalizar turno
                        </Text>
                      </TouchableOpacity>
                      <View style={{flexDirection:'row',marginTop:10,justifyContent:'center',alignItems:'center',padding:10,width:'100%'}}>
                        <Text style={{paddingLeft:5}}>
                          V.{global.versionNumber}
                        </Text>
                      </View>
                      
                      
                  </ScrollView>
                  
                  <View style={{backgroundColor:'white',padding:10,borderColor:'#333333',borderBottomWidth:1}}>
                   
                    <TouchableOpacity onPress={cerrarSesion} style={{flexDirection:'row',marginTop:10,alignItems:'center',padding:10,width:'100%'}}>
                      <AntDesign name="logout" size={25} color="black" />
                      <Text style={{paddingLeft:5}}>
                        Cerrar sesión
                      </Text>
                    </TouchableOpacity>
                  </View>
                 
                </View>
    );
    
}
const mapDispatchToProps = {
  setPedidoImprimir,
  setTiendas,
  setConexion,
  setPedidos,
  setTiendasCarrito,
  setPedidosPerdidos,
  setSocketConectado,
  setFooterVisible,
  setCargandoFiltros
  
};
const mapStateToProps = (state:any) => {
  return {
    pedidosFiltrados: state.pedidos.pedidosFiltrados,
   conexion: state.dimensions.conexion,
   socketConectado: state.pedidos.socketConectado,
   pedidosPerdidos:state.pedidos.pedidosPerdidos,
   height:state.dimensions.height,
   width:state.dimensions.width,
    tiendas: state.tiendas.tiendas,
    cargandoFiltros:state.config.cargandoFiltros,
    stock: state.stock.stock

  };
};
export default connect(mapStateToProps,mapDispatchToProps)(VistaMenuInterior);

const styles = createStyles({
  containerBtnSocket: {
    width:30,
    height:30,
    borderRadius:30,
    backgroundColor:'white',
    justifyContent:'center',
    alignItems:'center',
    marginTop:30,
    marginHorizontal:7.5

  },
  btnSocket:{
    width:22,
    height:22,
    borderRadius:22,
    backgroundColor:'#73E13F'
  },
  btnBuscador:{
    width:45,
    height:45,
    borderRadius:45,
    backgroundColor:'white',
    justifyContent:'center',
    alignItems:'center',
    marginTop:30
  },
  btnScanner:{
    width:47,
    height:47,
    borderRadius:45,
    borderColor:'white',
    borderWidth:1,
    justifyContent:'center',
    alignItems:'center',
    marginTop:30,
  },
  paddingBotones:{
    padding:5
  },
  styleBtnScaner:{
    display:'none'
  },
  inputBuscador: {
    borderRadius: 32,
    backgroundColor: "white",
    textAlign:'left',
    height:32,
    fontSize:16,
    outline:'none',
    paddingLeft:5
  },
});