import React, { useState, useEffect } from "react";
import { TouchableOpacity, View, Text } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { FlatList } from "react-native-gesture-handler";
import MasonryList from "@react-native-seoul/masonry-list";
import {
  setFooterVisible,
  setFacturaProductos,
  setFacturaComision,
} from "../../../reducers";

import { connect } from "react-redux";
import myCrypto from "../../../funcionalidades/myCrypto";
import ProductoFoto from "../../../components/ProductoFoto";
import { useTranslation } from "react-i18next";

const ProductsView = (props: any) => {
  let tienda;
  let familia;
  const [precioTotal, setPrecioTotal] = useState(0);
  const { t } = useTranslation();
  const [totalProductos, setTotalProductos] = useState(0);
  if (props.route != undefined) {
    tienda = props.route.params.tienda;
    familia = props.route.params.familia || "";
  } else {
    tienda = props.tienda;
    familia = props.familia || "";
  }

  useEffect(() => {
    props.navigation.setOptions({
      // headerTitle: tienda.name,
    });
    props.navigation.addListener("beforeRemove", () => {
      props.setFooterVisible(true);
    });
    if (familia != "") {
      setFamiliaSel(familia);
    }
  }, []);

  useEffect(() => {
    let precioTotalAux = 0;
    let totalProductosAux = 0;
    props.tiendas.map(function (tienda: any) {
      let precioTienda = 0;
      tienda.productos.map(function (item: any) {
        precioTienda = precioTienda + item.cantidad * item.precioTotalProducto;
        totalProductosAux = totalProductosAux + item.cantidad;
      });

      precioTotalAux = precioTotalAux + precioTienda;
    });
    setTotalProductos(totalProductosAux);
    if (totalProductosAux > 0) {
      props.setFooterVisible(false);
    } else {
      props.setFooterVisible(true);
    }
    setPrecioTotal(precioTotalAux);
  }, [props.tiendas]);
  const [familiaSel, setFamiliaSel] = useState(tienda.products[0]);

  function abrirVistaTicket(familia: any) {
    let aux = {
      tienda: tienda,
    };
    props.navigation.navigate("VistaResumenPedido", aux);
  }

  function onClickFamilia(familia: any) {
    setFamiliaSel(familia);
  }

  return (
    <View style={{ backgroundColor: "white", width: "100%", height: "100%" }}>
      <View style={{ marginTop: 8, marginBottom: 4 }}>
        <FlatList
          data={tienda.products}
          keyExtractor={(item: object, index: number): string =>
            index.toString()
          }
          showsHorizontalScrollIndicator={false}
          horizontal
          renderItem={function (item: any) {
            let familia = item.item;
            if (familia.products.length == 0) {
              return null;
            } else {
              return (
                <TouchableOpacity
                  style={[
                    {
                      justifyContent: "center",
                      height: 40,
                      borderBottomWidth: 3,
                    },
                    familia.gid == familiaSel.gid
                      ? { borderBottomColor: "#3AA597" }
                      : { borderBottomColor: "#c9c9c9" },
                  ]}
                  onPress={onClickFamilia.bind(this, familia)}
                >
                  <Text
                    style={[
                      {
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        fontSize: 16,
                        textAlign: "center",
                      },
                      familia.gid == familiaSel.gid
                        ? { color: "#3AA597" }
                        : { color: "black" },
                    ]}
                  >
                    {t(familia.name)}
                  </Text>
                </TouchableOpacity>
              );
            }
          }}
        />
      </View>

      <View
        style={{
          height: props.height - 180,
          marginTop: 30,
          paddingHorizontal: 12,
        }}
      >
        <MasonryList
          data={familiaSel.products}
          numColumns={3}
          style={{ paddingHorizontal: 12 }}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 120, paddingLeft: 4 }}
          renderItem={({ item }: any) => {
            return (
              <ProductoFoto
                tienda={tienda}
                navigation={props.navigation}
                producto={item}
              ></ProductoFoto>
            );
          }}
        />
      </View>
      {global.pantallaDividida ? null : (
        <View
          style={{ position: "absolute", bottom: 0, width: "100%", zIndex: 2 }}
        >
          {totalProductos > 0 ? (
            <View
              style={{ backgroundColor: "white", height: 50, width: "100%" }}
            >
              <View
                style={{
                  height: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  {totalProductos == 1 ? (
                    <Text style={{ fontSize: 16 }}>
                      {totalProductos}x Producto
                    </Text>
                  ) : (
                    <Text style={{ fontSize: 16 }}>
                      {totalProductos}x Productos
                    </Text>
                  )}
                </View>
                <View
                  style={{
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <Text style={{ color: "#BABABA", fontSize: 16 }}>Total2</Text>
                  <Text style={{ fontSize: 16 }}>{precioTotal.toFixed(2)}</Text>
                </View>
              </View>
            </View>
          ) : null}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <TouchableOpacity
              onPress={abrirVistaTicket}
              style={{
                backgroundColor: "#3AA597",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  padding: 13,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Aceptar
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </View>
  );
};

const mapDispatchToProps = {
  setFooterVisible,
  setFacturaProductos,
  setFacturaComision,
};

const mapStateToProps = (state: any) => {
  return {
    tiendas: state.carrito.tiendas,
    height: state.dimensions.height,
    ultimoPedido: state.pedidos.ultimoPedido,
    conexion: state.dimensions.conexion,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsView);
