import AsyncStorage from "@react-native-async-storage/async-storage";
import globales from "./globales";

function cargarFiltrosBBDD(userGid: any) {
  return new Promise(function (resolve, reject) {
    try {
      if (global?.db) {
        global.db.transaction(
          (tx: any) => {
            tx.executeSql(
              `SELECT * FROM userFiltros WHERE userGid = ${userGid.toString()};`,
              [],
              function (trans: any, result: any) {
                if (result != undefined) {
                  if (result.rows.length > 0) {
                    const filtros = result.rows.item(0).filtros;
                    resolve(filtros);
                  }
                  resolve("");
                }
              }
            );
          },
          function (err: any) {
            console.log("err", err), resolve("");
          }
        );
      } else {
        resolve("");
      }
    } catch (error) {
      resolve("")
    }
  });
}

async function login(email: any, pass: any) {
  return new Promise(function (resolve, reject) {
    let url = global.endpoint + "user-management/manager/auth/signin";
    console.log("eloy login ", url, email, pass);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: pass,
      }),
    }).then(async (response) => {
      const resData = await response.json();
      console.log("eloy resData", resData);
      if (response.status == 200) {
        const gid = resData?.data?.managers[0]?.fk_user;
        console.log('eloy prefiltros')
        let filtros = await cargarFiltrosBBDD(gid);
        console.log('eloy postfiltros')

        if (filtros) {
          console.log('eloy condicional')
          resData.filtros = await JSON.parse(filtros);
          await AsyncStorage.setItem("@filtrosAnteriores", JSON.parse(filtros));
          console.log('eloy postcondicional')
        }

        resolve(resData);
      } else {
        console.log("reject");
        reject(resData);
      }
    });
  });
}
async function cargarTiendas(idUsuario) {
  return new Promise(function (resolve, reject) {
    let url =
      global.endpoint +
      "management/shop/getAllOfManager/" +
      usuario.gid_manager;
    console.log("cargarTiendas ", url);
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
    })
      .then((response) => {
        console.log("devuelve llamada result--->", response.status);
        if (response.status == 200) {
          resolve(response.json());
        } else {
          if (response.status == 401) {
            global.cerrarSesion();
          }
          reject();
        }
      })
      .catch((error) => {
        console.error("Error cargarTiendas -> " + error);
        reject(error);
      });
  });
}
async function cargarStock() {
  return new Promise(function (resolve, reject) {
    let url =
      global.endpoint +
      "management/product/getAllOfManager/" +
      usuario.gid_manager;
    console.log("cargarStock ", url);
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
    })
      .then((response) => {
        console.log("devuelve llamada cargarStock--->", response.status);
        if (response.status == 200) {
          resolve(response.json());
        } else {
          if (response.status == 401) {
            global.cerrarSesion();
          }
          reject();
        }
      })
      .catch((error) => {
        console.error("Error cargarStock -> " + error);
        reject(error);
      });
  });
}
async function cambiarStock(
  idPuntoDeVenta: any,
  idProducto: any,
  stock: any,
  action: any
) {
  return new Promise(function (resolve, reject) {
    const url = `${global.endpoint}v2/point-sale/${idPuntoDeVenta}/product/${idProducto}/stock?action=${action}&quantity=${stock}`;

    fetch(url, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
    })
      .then((response) => {
        console.log("devuelve llamada cambiarStock--->", response.status);
        if (response.status == 200) {
          resolve(response.json());
        } else {
          if (response.status == 401) {
            global.cerrarSesion();
          }
          reject();
        }
      })
      .catch((error) => {
        console.error("Error cambiarStock -> " + error);
        reject(error);
      });
  });
}
async function regitrarUsuario() {
  return new Promise(function (resolve, reject) {
    console.log(
      "login ",
      global.endpoint + "user-management/customer/auth/signin",
      email,
      pass
    );
    fetch(global.endpoint + "user-management/customer/auth/signup", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: "joatest1@test.com",
        name: "joa",
        password: "qwer",
        language: "es",
        phone: "123456789",
        secret: "secrect",
        timezone: "madrid",
        token_fcm: "1234567889",
      }),
      mode: "no-cors",
    })
      .then((response) => {
        console.log("devuelve llamada result--->", response.status);
        if (response.status == 200) {
          resolve(response.json());
        } else {
          reject();
        }
      })
      .catch((error) => {
        console.error("Error login -> " + error);
        reject(error);
      });
  });
}
async function sincronizarPedidos(pedidos: any) {
  return new Promise(function (resolve, reject) {
    let url = global.endpoint + "order/orders/process-offline";

    console.log("sincronizarPedidos ", url, pedidos);
    console.log("stringify", JSON.stringify(pedidos));
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
      body: JSON.stringify({
        orders: pedidos,
      }),
    })
      .then((response) => {
        console.log("devuelve llamada sincronizarPedidos--->", response.status);
        if (response.status == 200) {
          resolve(response.json());
        } else {
          if (response.status == 401) {
            global.cerrarSesion();
          }
          reject();
        }
      })
      .catch((error) => {
        console.error("Error sincronizarPedidos -> " + error);
        reject(error);
      });
    /*setTimeout(function(){
        let aux={ok:[],ko:[]}
        resolve({data:aux})
      },50)*/
  });
}
async function modificarTienda(idTienda: any, abierta: any, tiempoEspera: any) {
  return new Promise(function (resolve, reject) {
    let url = global.endpoint + "v2/shop/" + idTienda;

    let busyMode = true;
    if (abierta) {
      busyMode = false;
    }
    fetch(url, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
      body: JSON.stringify({
        busyMode: busyMode,
        waitTime: tiempoEspera, // 5 min o 10 min, para quitarlo mandarle string vacio ''
      }),
    })
      .then((response) => {
        if (response.status == 200) {
          resolve(response.json());
        } else {
          if (response.status == 401) {
            global.cerrarSesion();
          }
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
    /*setTimeout(function(){
        let aux={ok:[],ko:[]}
        resolve({data:aux})
      },50)*/
  });
}
async function recargarCredito(
  amount: any,
  id_linea_pedido_credito: any,
  uuid: any
) {
  return new Promise(function (resolve, reject) {
    let url = global.endpoint + "order/orders/add-credit-to/" + uuid;

    console.log("amount", amount);
    console.log("id_linea_pedido_credito", id_linea_pedido_credito);
    console.log("uuid", uuid);
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
      body: JSON.stringify({
        gid_order_line_credit: id_linea_pedido_credito,
        amount: amount,
      }),
    })
      .then((response) => {
        if (response.status == 200) {
          resolve(response.json());
        } else {
          if (response.status == 401) {
            global.cerrarSesion();
          }
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
async function crearPedido(carritoAux: any) {
  return new Promise(function (resolve, reject) {
    let orders = globales.actualizarOrders(carritoAux);
    let url = global.endpoint + "v2/order";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
      body: JSON.stringify({
        orders: orders,
      }),
    })
      .then((response) => {
        console.log("response.status", response.status);
        if (response.status == 200) {
          resolve(response.json());
        } else {
          if (response.status == 401) {
            global.cerrarSesion();
          }
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
async function crearPedidoDatafono(carritoDatafono: any) {
  return new Promise(function (resolve, reject) {
    console.log("usuarioCalss,crearPedidoDatafono", carritoDatafono);
    let orders = globales.actualizarOrders(carritoDatafono.carrito.orders[0]);
    console.log("orders", orders);
    orders[0].cashPaid = orders[0].amount;
    orders.forEach((order: any) => (order.cashPaid = order.amount));
    let url = global.endpoint + "v2/order";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
      body: JSON.stringify({
        merchantReference: carritoDatafono.transactionID,
        orders: orders,
      }),
    })
      .then(async (response) => {
        console.log("response.status", response.status);

        if (response.status == 200) {
          resolve(response.json());
        } else {
          if (response.status == 401) {
            let respuesta = await response.json();
            console.log("respuesta", respuesta);
            global.cerrarSesion();
          }
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
async function completarPedidos(ordersIds: any, status: any) {
  return new Promise(function (resolve, reject) {
    console.log("ordersIds", ordersIds, status);
    let url = global.endpoint + "v2/order/status";
    fetch(url, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
      body: JSON.stringify({
        orderIds: ordersIds,
        status: status,
      }),
    })
      .then((response) => {
        console.log("response.status", response.status);
        if (response.status == 200) {
          resolve(response.json());
        } else {
          if (response.status == 401) {
            global.cerrarSesion();
          }
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
async function obtenerFacturas(idPedido: any) {
  return new Promise(function (resolve, reject) {
    let url = global.endpoint + "v2/invoice?orderId=" + idPedido;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
    })
      .then((response) => {
        console.log("response.status", response.status);
        if (response.status == 200) {
          resolve(response.json());
        } else {
          if (response.status == 401) {
            global.cerrarSesion();
          }
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
async function cargarTiendasStock(idUsuario: any) {
  return new Promise(function (resolve, reject) {
    let url =
      global.endpoint +
      "v2/shop?page=1&limit=50&sortBy=asc&userId=" +
      idUsuario;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
    })
      .then((response) => {
        console.log("response.status", response.status);
        if (response.status == 200) {
          resolve(response.json());
        } else {
          if (response.status == 401) {
            global.cerrarSesion();
          }
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
async function cargarPuntosDeVentaTienda(idTienda: any) {
  return new Promise(function (resolve, reject) {
    let url =
      global.endpoint + "v2/point-sale?shopId=" + idTienda + "&active=true";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
    })
      .then((response) => {
        console.log("response.status", response.status);
        if (response.status == 200) {
          resolve(response.json());
        } else {
          if (response.status == 401) {
            global.cerrarSesion();
          }
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
async function cargarProductos(idPuntoDeVenta: any) {
  return new Promise(function (resolve, reject) {
    let url = global.endpoint + "v2/product?pointOfSaleId=" + idPuntoDeVenta;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
    })
      .then((response) => {
        console.log("response.status", response.status);
        if (response.status == 200) {
          resolve(response.json());
        } else {
          if (response.status == 401) {
            global.cerrarSesion();
          }
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
async function cambiarActiveProducto(idProducto: any, active: any) {
  return new Promise(function (resolve, reject) {
    let url = global.endpoint + "v2/product/" + idProducto;
    let activeAux = 0;
    if (active) {
      activeAux = 1;
    }
    fetch(url, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
      body: JSON.stringify({
        active: activeAux,
      }),
    })
      .then((response) => {
        console.log("response.status", response.status);
        if (response.status == 200 || response.status == 201) {
          resolve(response.json());
        } else {
          if (response.status == 401) {
            global.cerrarSesion();
          }
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export default {
  cambiarActiveProducto,
  cargarProductos,
  cargarTiendasStock,
  cargarPuntosDeVentaTienda,
  crearPedido,
  obtenerFacturas,
  completarPedidos,
  login,
  cargarTiendas,
  cargarStock,
  cambiarStock,
  sincronizarPedidos,
  modificarTienda,
  recargarCredito,
  crearPedidoDatafono,
};
