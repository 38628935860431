import React, { useState } from "react";
import { StyleSheet, Text, Image, TouchableOpacity } from "react-native";

// Components
import Divider from "./Divider";
import BackModal from "./modals/BackModal";

const LogoHeader = ({ title, navigation }) => {
  const [counter, setCounter] = useState(0);
  const [lastClick, setLastClick] = useState(null);
  const [modal, setModal] = useState("");

  const modalHandler = () => {
    const now = new Date().getTime();

    if (lastClick && now - lastClick < 3000) {
      setCounter(counter + 1);
      if (counter === 3) {
        setModal("Back");
        setCounter(0);
      }
    } else {
      setCounter(0);
    }
    setLastClick(now);
  };
  return (
    <TouchableOpacity
      style={{
        paddingLeft: 20,
        flexDirection: "row",
        alignItems: "center",
      }}
      onPress={modalHandler}
    >
      <Image
        style={{ height: 50, width: 85 }}
        source={require("../../../assets/images/iberostar.png")}
      />
      <Divider width={20} />
      <Text style={{ fontSize: 22, color: "black", fontWeight: "500" }}>
        {title}
      </Text>
      <BackModal
        navigation={navigation}
        setModal={setModal}
        visible={modal === "Back"}
      />
    </TouchableOpacity>
  );
};

export default LogoHeader;

const styles = StyleSheet.create({});
