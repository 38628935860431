import Producto from './Producto'

export default class Familia{
  gid=0
  name=""
  image= null
  products=[]
  constructor(obj:any) {
    this.gid=obj.gid || 0
    this.name=obj.name || "Bebida"
    this.image=obj.image ||  null
    this.products=obj.products || []
    this.cargarProductos(this.products)
  }
  cargarProductos(productos:any){
    let thisFamilia = this
    thisFamilia.products=[]
    productos.map(function(producto:any,indice:number) {
      let productoAux = new Producto(producto)
      thisFamilia.products.push(productoAux)
    });
  }
} 