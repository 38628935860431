import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { useTranslation } from "react-i18next";

// Components
import ModalBase from "./ModalBase";
import Divider from "../Divider";

// Context
import { useLanguage } from "../../../../contexts/LanguageContext";

// i18n
import { languageNames } from "../../../../i18n/languageNames";

// Methods
import { getFlagIcon } from "../LanguageSelector/methods/getFlagIcon";

const LanguageModal = ({ visible, setModal }) => {
  const { changeLanguage } = useLanguage();
  const { t } = useTranslation();

  const languageHandler = (newLanguage: string) => {
    console.log("click");
    changeLanguage(newLanguage);
    setModal();
  };

  return (
    <ModalBase modalVisible={visible} handleModal={() => setModal()}>
      <View style={styles.inputWrapper}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{t("selectLanguage")}</Text>
        </View>
        <View style={styles.bodyContainer}>
          {Object.keys(languageNames).map((lang: any, index: number) => {
            return (
              <>
                <TouchableOpacity
                  style={styles.item}
                  onPress={() => languageHandler(lang)}
                >
                  {getFlagIcon(lang)}
                  <Divider width={10} />
                  <Text style={styles.itemLabel}>{languageNames[lang]}</Text>
                </TouchableOpacity>
                {index !== Object.keys(languageNames).length - 1 && (
                  <Divider height={22} />
                )}
              </>
            );
          })}
        </View>
      </View>
    </ModalBase>
  );
};

export default LanguageModal;

const styles = StyleSheet.create({
  inputWrapper: {
    width: "100%",
  },
  buttonWrapper: { height: 50, flexDirection: "row" },
  bodyBackground: {
    height: "100%",
    width: "100%",
  },
  titleContainer: {
    width: "100%",
    padding: 12,
    paddingLeft: 16,
  },
  title: { fontSize: 18 },
  item: {
    flexDirection: "row",
    width: "100%",
    paddingHorizontal: 6,
    alignItems: "center",
  },
  itemLabel: { fontSize: 22 },
  bodyContainer: {
    padding: 12,
    width: "100%",
    backgroundColor: "white",
  },
});
