import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import Divider from "./Divider";

const SecondaryIconButton = ({ title, onPress, icon }) => {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <MaterialCommunityIcons name={icon} size={20} color="#3AA597" />
      <Divider width={5} />
      <Text style={styles.text}>{title}</Text>
    </TouchableOpacity>
  );
};

export default SecondaryIconButton;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: 50,
    borderRadius: 30,
    flexDirection: "row",
    paddingHorizontal: 8,
    borderWidth: 1,
    borderColor: "#3AA597",
    backgroundColor: "white",
  },
  text: {
    fontSize: 18,
    textAlign: "center",
    color: "#3AA597",
  },
});
