import React from "react";
import { Feather } from "@expo/vector-icons";
import { Text, View, StyleSheet } from "react-native";

// Components
import Divider from "../Divider";
import ActionButton from "./ActionButton";

const RemoveProductStatic = ({
  quantity,
  onPress,
  buttonSize = 30,
  iconSize = 25,
}) => {
  return (
    <View style={styles.actions}>
      <Text style={styles.qtyText}>{quantity} x</Text>
      <Divider height={5} />
      <ActionButton color={"#FFD1D1"} onPress={onPress} size={buttonSize}>
        <Feather
          name={quantity > 1 ? "minus" : "trash-2"}
          size={quantity > 1 ? iconSize : iconSize - 4}
          color={"#C61414"}
        />
      </ActionButton>
    </View>
  );
};

export default RemoveProductStatic;

const styles = StyleSheet.create({
  actions: {
    height: "100%",
    minWidth: 30,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    overflow: "hidden",
  },
  qtyText: {
    fontSize: 24,
    color: "black"
  },
});
