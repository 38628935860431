import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, View } from "react-native";
import { getIngredientsText } from "../../methods/getIngredientsText";
import { getVariantsText } from "../../methods/getVariantsText";

const MenuLines = ({ plates }) => {
  const {t} = useTranslation()
  return (
    <View>
      {plates.map((plate: any) => (
        <View style={styles.plateWrapper}>
          {plate.productos.map((product: any) => {
            const variantText = getVariantsText(product.variantesSel) || null;
            const ingredientsText =
              getIngredientsText(product.ingredientesSinSel) || null;
            return (
              <View style={styles.plateWrapper}>
                <Text style={styles.title}>{t(product.name)}</Text>
                {ingredientsText && (
                  <Text style={styles.text}>{t("without")+": " + ingredientsText}</Text>
                )}
                {variantText && <Text style={styles.text}>{variantText}</Text>}
              </View>
            );
          })}
        </View>
      ))}
    </View>
  );
};

export default MenuLines;

const styles = StyleSheet.create({
  plateWrapper: {},
  title: {
    color: "black",
    fontSize: 24,
    fontWeight: "bold",
  },
  text: {
    color: "black",
    fontSize: 24,
  },
});
