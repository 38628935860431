import React from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import { useTranslation } from "react-i18next";

// Components
import MainButton from "../MainButton";
import ModalBase from "./ModalBase";
import Divider from "../Divider";

const FinishModal = ({ visible, setModal }) => {
  const { t } = useTranslation();
  return (
    <ModalBase modalVisible={visible} handleModal={() => setModal()}>
      <View style={styles.inputWrapper}>
        <Image
          style={styles.image}
          source={require("../../../../assets/images/confirm.png")}
        />
        <Divider height={20} />
        <Text style={styles.text}>{t("orderCompleted")}</Text>
        <Divider height={20} />
        <View style={styles.buttonWrapper}>
          <MainButton title={t("accept")} onPress={() => setModal()} />
        </View>
      </View>
    </ModalBase>
  );
};

export default FinishModal;

const styles = StyleSheet.create({
  inputWrapper: {
    width: "100%",
    height: 300,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonWrapper: {
    height: 50,
    flexDirection: "row",
    maxWidth: 200,
    width: "100%",
  },
  image: {
    width: 150,
    resizeMode: "contain",
    height: 150,
  },
  inputContainer: {
    width: 190,
    height: 45,
  },
  text: {
    fontSize: 20,
    color: "black",
    textAlign: "center",
  },
  error: {
    fontSize: 14,
    color: "red",
    textAlign: "center",
  },
});
