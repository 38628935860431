import {GET_PRODUCTO,ADD_PRODUCTO,DELETE_PRODUCTO,VACIAR_CARRITO,SET_TIENDAS_CARRITO,SET_TIENDA,SET_COMENTARIO,SET_EMAIL,SET_CASHLESS} from "./carritoTypes";



export const addProducto = (tienda:any,producto:any) => {
  return (dispatch:any) => {
    dispatch({
      type: ADD_PRODUCTO,
      payload: {
        tienda:tienda,
        producto: producto
      }
    });
  };
};
export const eliminarProducto = (tienda:any,producto:any,eliminarTodo:any) => {
  return (dispatch:any) => {
    dispatch({
      type: DELETE_PRODUCTO,
      payload: {
        tienda:tienda,
        producto: producto,
        eliminarTodo:eliminarTodo || false
      }
    });
  };
};
export const obtenerProductos = (tienda:any,id:any) => {
  return (dispatch:any) => {
    let respuesta = dispatch({
      type: GET_PRODUCTO,
      payload: {
        tienda:tienda,
        id: id
        
      }
    });
     //console.log("respuesta",respuesta)
    return(respuesta.payload.productosAux)
  };
};
export const vaciarCarrito = () => {
  return (dispatch:any) => {
    let respuesta = dispatch({
      type: VACIAR_CARRITO
    });
   
  };
};
export const setTiendasCarrito = (tiendas:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_TIENDAS_CARRITO,
      payload: {
        tiendas: tiendas
      }
    });
  };
};
export const setTienda = (tienda:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_TIENDA,
      payload: {
        tienda: tienda
      }
    });
  };
};

export const setComentario = (comentario:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_COMENTARIO,
      payload: {
        comentario: comentario
      }
    });
  };
};
export const setEmail = (eamil:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_EMAIL,
      payload: {
        eamil: eamil
      }
    });
  }
}
export const setCashLess = (cashLess:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_CASHLESS,
      payload: {
        cashLess: cashLess
      }
    });
  }
}
