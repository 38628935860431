import { useTranslation } from "react-i18next";

export function getVariantsText(variantes: any) {
  const {t} = useTranslation()
  let srtVariantesAux = "";
  let primero = true;
  if (variantes != undefined) {
    variantes.map(function (grupoVariante: any, indice: number) {
      if (grupoVariante != null && grupoVariante != undefined) {
        let strOpciones = "";
        if (grupoVariante.opciones != undefined) {
          grupoVariante.opciones.map(function (opcion: any, index: number) {
            if (opcion.valor) {
              var strComa = ", ";
              if (primero) {
                strComa = "";
                primero = false;
              }
              let strCantidad = "";
              if (opcion.quantity > 1) {
                strCantidad = opcion.quantity + "x ";
              }
              strOpciones += strComa + t(opcion.name);
            }
          });
        }
        srtVariantesAux += strOpciones;
      }
    });
  }
  return srtVariantesAux;
}
