import React from "react";
import { View } from "react-native";

const Divider = ({ height = 1, width = 1, type, color = null, ...rest }) => {
  return (
    <View
      style={{
        height: type === "full-height" ? "100%" : height,
        width: type === "full-width" ? "100%" : width,
        backgroundColor:color,
        ...rest,
      }}
    />
  );
};

export default Divider;
