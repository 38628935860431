async function getDatosTienda(idTienda:any,servicio:any,localizaciones:any){
  return new Promise(function(resolve, reject) {
    //var url = global.endpoint + 'management-api/shop/get/'+'3'+'?service='+servicio
    var url = global.endpoint + 'management/shop/get/'+idTienda+'?service='+(servicio || 'pick_up')
    if(localizaciones!=undefined){
      if(localizaciones.length>0){
        let paramLocalizacion = ''
        paramLocalizacion='&location='+localizaciones[localizaciones.length-1].select
        // paramLocalizacion='&location='+1
        //url = global.endpoint + 'management-api/shop/get/'+idTienda+'?service='+servicio+paramLocalizacion
      }
    }
    console.log("getDatosTienda ",url)

    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':"Bearer "+global.usuario.jwt
      }
      
    })
      .then(response => {
          console.log('devuelve llamada result--->', response.status)
          if (response.status == 200) {
              resolve(response.json());
          }else{
              reject();
          }
      })
      .catch((error) => {
          console.error('Error getDatosTienda -> '+error);
          reject(error);
      })
    });
}


export default {getDatosTienda};


