import React from "react";
import { useTranslation } from "react-i18next";
import { Text, View, StyleSheet } from "react-native";
import { getIngredientsText } from "../../methods/getIngredientsText";
import { getVariantsText } from "../../methods/getVariantsText";
import Divider from "../Divider";

// Components
import AddProduct from "./AddProduct";
import MenuLines from "./MenuLines";
import RemoveProduct from "./RemoveProduct";

const ProductItem = ({ product, onAdd, onDecrease }) => {
  const { cantidad, name, ingredientesSinSel, variantesSel } = product;
  const { t } = useTranslation();
  const addHandler = () => {
    onAdd();
  };

  const removeHandler = () => {
    onDecrease();
  };

  const variantText = getVariantsText(variantesSel) || null;
  const ingredientsText = getIngredientsText(ingredientesSinSel) || null;
  return (
    <View style={styles.container}>
      <RemoveProduct
        quantity={cantidad}
        onPress={removeHandler}
        buttonSize={28}
        iconSize={24}
      />
      <Divider width={10}/>
      <View style={styles.detailsColumn}>
        <Text style={[styles.text, { fontWeight: "bold" }]}>{t(name)}</Text>
        {ingredientsText && (
          <Text style={styles.text}>
            {t("without") + ": " + ingredientsText}
          </Text>
        )}
        {variantText && <Text style={styles.text}>{variantText}</Text>}
        <MenuLines plates={product.platosSel} />
      </View>
      <AddProduct onAdd={addHandler} buttonSize={28} iconSize={24} />
    </View>
  );
};

export default ProductItem;

const styles = StyleSheet.create({
  container: {
    marginVertical: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  detailsColumn: {
    flex: 1,
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingRight: 10,
    flexDirection: "column",
  },
  text: {
    color: "black",
    fontSize: 24,
  },
});
