import React, { useState, useEffect,useRef } from "react";
import {
  Text,
  FlatList,
  ScrollView,
  TouchableOpacity,
  View,
} from "react-native";

import { AntDesign, Entypo } from "@expo/vector-icons";

import { addProducto } from "../../application/reducers";
import { connect } from "react-redux";
import Producto from "../../application/objetos/Producto";
import MainButton from "./FoodStallScreen/components/MainButton";
import { useTranslation } from "react-i18next";
import Divider from "./FoodStallScreen/components/Divider";

const listadoVariantes = (props: any) => {
  const scrollViewRef = useRef(null)
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  var producto = props.route.params.producto;
  var tienda = props.route.params.tienda;
  const { t } = useTranslation();
  //para el menu
  var siguientePaso = props.route.params.siguientePaso;
  let initArray: Array<any> = [];

  console.log("producto", producto);
  let productoAux = new Producto(JSON.parse(JSON.stringify(producto)));
  productoAux.amount = productoAux.amount * 100;
  const [checksVariantes, setChecksVariantes] = useState(
    productoAux.variantesSel
  );
  const [checksIngredientes, setChecksIngredientes] = useState(initArray);
  function goBack() {
    props.navigation.goBack();
  }

  function changeVariantes(indiceGrupo: number, indiceOpcion: number) {
    var checksVariantesAux = checksVariantes.slice();

    if (checksVariantesAux[indiceGrupo].tipo != "check") {
      checksVariantesAux[indiceGrupo].opciones.map(function (
        opcion: any,
        indice: number
      ) {
        opcion.valor = false;
      });
      checksVariantesAux[indiceGrupo].opciones[indiceOpcion].valor = true;
    } else {
      let valor = checksVariantesAux[indiceGrupo].opciones[indiceOpcion].valor;
      if (valor) {
        checksVariantesAux[indiceGrupo].numSel -=
          checksVariantesAux[indiceGrupo].opciones[indiceOpcion].cantidad;
        checksVariantesAux[indiceGrupo].opciones[indiceOpcion].valor = false;
        checksVariantesAux[indiceGrupo].opciones[indiceOpcion].cantidad = 1;
      } else {
        let maximo = checksVariantes[indiceGrupo].num_max;
        if (
          checksVariantesAux[indiceGrupo].numSel < maximo ||
          maximo === true
        ) {
          checksVariantesAux[indiceGrupo].numSel++;
          checksVariantesAux[indiceGrupo].opciones[indiceOpcion].valor = true;
        }
      }
    }
    console.log("checksVariantesAux", checksVariantesAux);
    setChecksVariantes(checksVariantesAux);
  }
  function addProductoCarrito() {
    let ingredientesSinSel: Array<any> = [];
    checksIngredientes.map(function (ingrediente: any, indice: number) {
      if (!ingrediente.valor) {
        ingredientesSinSel.push(ingrediente);
      }
    });
    productoAux.ingredientesSinSel = ingredientesSinSel;
    productoAux.variantesSel = checksVariantes;
    goBack();
    if (
      productoAux.product_type == "menu" ||
      productoAux.product_type == "Menú"
    ) {
      console.log("abro menu");

      abrirProductoMenu();
    } else {
      if (siguientePaso != undefined) {
        siguientePaso(productoAux);
      } else {
        props.addProducto(tienda, productoAux);
      }
    }
  }
  function abrirProductoMenu() {
    let aux = {
      producto: productoAux,
      tienda: tienda,
    };
    props.navigation.navigate("productoMenu", aux);
  }
  function addOpcion(indiceGrupo: number, indiceOpcion: number) {
    //var checksVariantesAux =checksVariantes.slice();
    let maximo = checksVariantes[indiceGrupo].num_max;
    let cantidad = checksVariantes[indiceGrupo].numSel;
    if (cantidad < maximo || maximo === true) {
      var checksVariantesAux = checksVariantes.slice();
      checksVariantesAux[indiceGrupo].numSel++;
      checksVariantesAux[indiceGrupo].opciones[indiceOpcion].cantidad++;
      setChecksVariantes(checksVariantesAux);
    }
  }
  function eliminarOpcion(indiceGrupo: number, indiceOpcion: number) {
    var checksVariantesAux = checksVariantes.slice();
    let cantidad =
      checksVariantesAux[indiceGrupo].opciones[indiceOpcion].cantidad;
    if (cantidad == 1) {
      checksVariantesAux[indiceGrupo].opciones[indiceOpcion].valor = false;
    } else {
      checksVariantesAux[indiceGrupo].opciones[indiceOpcion].cantidad--;
    }
    checksVariantesAux[indiceGrupo].numSel--;
    setChecksVariantes(checksVariantesAux);
  }
  function changeIngrediente(indice: any) {
    var checksIngredientesAux = checksIngredientes.slice();
    console.log(checksIngredientesAux, indice);
    checksIngredientesAux[indice].valor = !checksIngredientesAux[indice].valor;
    setChecksIngredientes(checksIngredientesAux);
  }
  useEffect(() => {
    let checkIngredientesAux: Array<any> = [];
    console.log("producto.ingredients", producto.ingredients);
    if (producto.ingredients != undefined) {
      producto.ingredients.map(function (ingrediente: any, indice: number) {
        let aux = {
          nombre: ingrediente,
          valor: true,
        };
        checkIngredientesAux.push(aux);
      });
    }
    setChecksIngredientes(checkIngredientesAux);
  }, []);

  const handleScroll = (event) => {
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
    const isScrolledToBottom =
      layoutMeasurement.height + contentOffset.y >= contentSize.height - 20;
    setScrolledToBottom(isScrolledToBottom);
  };

  const scrollToBottom = () => {
    // Implementa la lógica para hacer scroll hacia abajo
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({ animated: true });
    }
  };

  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={() => {}}
      style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
    >
      <View
        style={[
          {
            alignItems: "center",
            justifyContent: "center",
            height: 600,
          },
          global.pantallaDividida
            ? { width: "60%", marginLeft: "40%" }
            : { width: "100%" },
        ]}
      >
        <View
          style={{
            width: "80%",
            height: "90%",
            borderRadius: 12,
            backgroundColor: "white",
            borderColor: "#b2b2b2",
            borderWidth: 1,
          }}
        >
          <TouchableOpacity
            onPress={goBack}
            style={{ padding: 10, position: "absolute", right: 0, zIndex: 2 }}
          >
            <AntDesign name="close" size={32} color="#3AA597" />
          </TouchableOpacity>

          <ScrollView
          ref={scrollViewRef}
            showsVerticalScrollIndicator={true}
            scrollEventThrottle={16}
            contentContainerStyle={{ paddingBottom: 20 }}
            onScroll={handleScroll}
          >
            <TouchableOpacity activeOpacity={1} key={1}>
              {checksIngredientes.sort((a, b) => a.gid - b.gid).length > 0 ? (
                <View style={{ padding: 10, width: "100%" }}>
                  <Text
                    style={{
                      fontSize: 20,
                      padding: 10,
                      fontWeight: "bold",
                      color: "#3AA597",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {t(producto.ingredients_group_nombre)}
                  </Text>
                  <View style={{}}>
                    <FlatList
                      data={checksIngredientes}
                      //keyExtractor={(item:object, index:number) => {return(index.toString())}}
                      numColumns={2}
                      showsVerticalScrollIndicator={false}
                      renderItem={({ item, index }: any) => {
                        return (
                          <TouchableOpacity
                            key={index}
                            style={[
                              {
                                width: "50%",
                                borderWidth: 1,
                                borderColor: "#dadada",
                                borderRadius: 20,
                                padding: 4,
                                paddingTop: 8,
                                paddingBottom: 8,
                                margin: 2,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              },
                              item.valor
                                ? {
                                    backgroundColor: "#009683",
                                    borderColor: "#009683",
                                  }
                                : { backgroundColor: "white", opacity: 0.6 },
                            ]}
                            onPress={(data) => {
                              changeIngrediente(index);
                            }}
                          >
                            <Text
                              style={[
                                {
                                  marginLeft: 4,
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  textAlignVertical: "center",
                                },
                                item.valor
                                  ? { color: "white" }
                                  : { color: "black" },
                              ]}
                            >
                              {t(item.nombre)}{" "}
                            </Text>
                          </TouchableOpacity>
                        );
                      }}
                    />
                  </View>
                </View>
              ) : null}
            </TouchableOpacity>
            <TouchableOpacity activeOpacity={1} key={2}>
              {checksVariantes
                .sort((a, b) => a.gid - b.gid)
                .map(function (variante: any, indiceGrupo: number) {
                  let textoMaximo = "";
                  if (parseInt(variante.num_max) > 0) {
                    textoMaximo = "(Máx. " + parseInt(variante.num_max) + ")";
                  }
                  let indiceOpcion = 0;
                  return (
                    <View style={{ padding: 10, width: "100%" }}>
                      <Text
                        style={{
                          fontSize: 20,
                          padding: 10,
                          fontWeight: "bold",
                          color: "#3AA597",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {t(variante.name)} {textoMaximo}
                      </Text>
                      <View style={{}}>
                        <FlatList
                          data={variante.opciones.sort((a, b) => a.gid - b.gid)}
                          //keyExtractor={(item:object, index:number) => {return(index.toString())}}
                          numColumns={2}
                          showsVerticalScrollIndicator={false}
                          renderItem={({ item, index }: any) => {
                            return (
                              <TouchableOpacity
                                key={index}
                                style={[
                                  {
                                    flex: 1,
                                    padding: 4,
                                    borderWidth: 1,
                                    borderColor: "#dadada",
                                    borderRadius: 20,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    margin: 2,
                                    flexDirection: "row",
                                  },
                                  item.valor
                                    ? {
                                        backgroundColor: "#009683",
                                        borderColor: "#009683",
                                      }
                                    : { backgroundColor: "white" },
                                ]}
                                onPress={(data) => {
                                  changeVariantes(indiceGrupo, index);
                                }}
                              >
                                <View
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Text
                                    style={[
                                      {
                                        fontSize: 20,
                                        textAlignVertical: "center",
                                        fontWeight: "bold",
                                      },
                                      item.valor
                                        ? { color: "white" }
                                        : { color: "black" },
                                    ]}
                                  >
                                    {" "}
                                    {t(item.nombre)}
                                  </Text>
                                  {item.incremento > 0 ? (
                                    <Text
                                      style={[
                                        {
                                          marginTop: 4,
                                          fontSize: 20,
                                          lineHeight: 15,
                                          textAlignVertical: "center",
                                        },
                                        item.valor
                                          ? { color: "white" }
                                          : { color: "black" },
                                      ]}
                                    >
                                      {" "}
                                      {item.incremento}€
                                    </Text>
                                  ) : null}
                                </View>
                                <Divider width={10} />
                                {item.valor && variante.permitir_cantidad ? (
                                  <View
                                    style={{
                                      alignItems: "center",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <TouchableOpacity
                                      style={{}}
                                      onPress={eliminarOpcion.bind(
                                        this,
                                        indiceGrupo,
                                        index
                                      )}
                                    >
                                      <Entypo
                                        name="squared-minus"
                                        size={28}
                                        color="#E1E1E1"
                                      />
                                    </TouchableOpacity>
                                    <Text
                                      style={[
                                        {
                                          fontSize: 20,
                                          lineHeight: 20,
                                          textAlignVertical: "center",
                                          textAlign: "center",
                                        },
                                        item.valor
                                          ? { color: "white" }
                                          : { color: "black" },
                                      ]}
                                    >
                                      {" "}
                                      {item.cantidad}{" "}
                                    </Text>
                                    <TouchableOpacity
                                      style={{}}
                                      onPress={addOpcion.bind(
                                        this,
                                        indiceGrupo,
                                        index
                                      )}
                                    >
                                      <Entypo
                                        name="squared-plus"
                                        size={28}
                                        color="#E1E1E1"
                                      />
                                    </TouchableOpacity>
                                  </View>
                                ) : null}
                              </TouchableOpacity>
                            );
                          }}
                        />
                      </View>
                    </View>
                  );
                })}
            </TouchableOpacity>
          </ScrollView>

          <View
            style={{ width: "100%", alignItems: "center", marginBottom: 20 }}
          >
            {scrolledToBottom ? (
              <View style={{ width: 200, height: 50 }}>
                <MainButton onPress={addProductoCarrito} title={t("add")} />
              </View>
            ) : (
              <TouchableOpacity
                style={{
                  width: 50,
                  height: 50,
                  borderWidth: 1,
                  borderColor: "#3AA597",
                  borderRadius: 25,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onPress={scrollToBottom}
              >
                <AntDesign name="down" size={24} color="#3AA597" />
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};
const mapDispatchToProps = {
  addProducto,
};
const mapStateToProps = (state: any) => {
  return {
    height: state.dimensions.height,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(listadoVariantes);
