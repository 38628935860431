import usuarioCalls from "../funcionalidades/usuarioCalls";
import CarritoDatafono from "./CarritoDatafono";
import NetInfo from "@react-native-community/netinfo";
import Pedido from "./Pedido";
import AsyncStorage from "@react-native-async-storage/async-storage";
export default class Usuario {
  gid = null;
  email = null;
  name = null;
  creditos = [];
  bonos = [];
  setting = {};
  alarm = [];
  print = [];
  shops = [];
  jwt = "";
  gid_manager = "";
  pedidosEstadosCambiados = [];
  filtros = {};
  intervaloConexion = "";
  constructor(obj: any) {
    this.gid_manager = obj.gid_manager || "";
    this.gid = obj.gid || "";
    this.email = obj.email || "";
    this.name = obj.name || "";
    this.creditos = obj.creditos || [];
    this.bonos = obj.bonos || [];
    this.alarm = obj.alarm || [];
    this.print = obj.print || [];
    this.setting = obj.setting || {};
    this.shops = obj.shops || [];
    this.jwt = obj.jwt || "";
    this.filtros = obj.filtros || {};
  }
  async login(email: any, pass: any) {
    //return await usuarioCalls.login(this.email, this.pass);
    var t = this;
    return new Promise(function (resolve, reject) {
      usuarioCalls.login(email, pass).then(
        function (result: any) {
          console.log("eloy result", result);
          let datosUsuario = result.data.managers[0];
          t.gid = datosUsuario.fk_user;
          //t.gid=datosUsuario.gid
          t.gid_manager = datosUsuario.gid;
          t.name = datosUsuario.name;
          t.email = datosUsuario.email;
          t.setting = datosUsuario.setting;
          t.shops = datosUsuario.shops;
          t.jwt = result.data.jwt;
          if (result.filtros != undefined) {
            t.filtros = JSON.parse(result.filtros);
          }
          resolve(t.gid);
        },
        function (err: any) {
          console.log("Error login -> " + err);
          reject(err);
        }
      );
    });
  }
  async cargarTiendas() {
    //return await usuarioCalls.cargarTiendas(this.email, this.pass);
    var t = this;
    return new Promise(function (resolve, reject) {
      usuarioCalls
        .cargarTiendas(t.gid)
        .then(function (result: any) {
          console.log("result", result);
          resolve(result.data);
        })
        .catch((error: any) => {
          console.error("Error cargarTiendas -> " + error);
          reject();
        });
    });
  }
  async cargarStock() {
    //return await usuarioCalls.cargarTiendas(this.email, this.pass);
    var t = this;
    return new Promise(function (resolve, reject) {
      usuarioCalls
        .cargarStock()
        .then(function (result: any) {
          console.log("result", result);

          resolve(result.data);
        })
        .catch((error: any) => {
          console.error("Error cargarTiendas -> " + error);
          reject();
        });
    });
  }
  async cambiarStock(
    idPuntoDeVenta: any,
    idProducto: any,
    stock: any,
    action: any
  ) {
    var t = this;
    let actionAux = "SET";
    switch (action) {
      case "Añadir":
        actionAux = "INCREMENT";
        break;
      case "Quitar":
        actionAux = "DECREASE";
        break;
      case "Establecer":
        actionAux = "SET";
        break;
    }
    return new Promise(function (resolve, reject) {
      usuarioCalls
        .cambiarStock(idPuntoDeVenta, idProducto, stock, actionAux)
        .then(function (result: any) {
          console.log("result", result);
          resolve(result.data);
        })
        .catch((error: any) => {
          console.error("Error cambiarStock -> " + error);
          reject();
        });
    });
  }
  async cargarPedidos() {
    var t = this;
    return new Promise(function (resolve, reject) {
      global.socket.on("orders:read:result", (data: any) => {
        console.log("result", data);
      });
      global.socket.emit("orders:read", {});
    });
  }
  async sincronizarPedidos(pedidos: any) {
    var t = this;
    return new Promise(function (resolve, reject) {
      usuarioCalls
        .sincronizarPedidos(pedidos)
        .then(function (result: any) {
          console.log("result", result);
          resolve(result);
        })
        .catch((error: any) => {
          console.error("Error sincronizarPedidos -> " + error);
          reject();
        });
    });
  }
  async modificarTienda(idTienda: any, abierta: any, tiempoEspera: any) {
    var t = this;
    return new Promise(function (resolve, reject) {
      usuarioCalls
        .modificarTienda(idTienda, abierta, tiempoEspera)
        .then(function (result: any) {
          console.log("result", result);
          resolve(result);
        })
        .catch((error: any) => {
          console.error("Error cerrarAbrirTiendas -> " + error);
          reject();
        });
    });
  }
  async recargarImporte(amount: any, id_linea_pedido_credito: any, uuid: any) {
    var t = this;
    return new Promise(function (resolve, reject) {
      usuarioCalls
        .recargarCredito(amount, id_linea_pedido_credito, uuid)
        .then(function (result: any) {
          console.log("result", result);
          resolve(result);
        })
        .catch((error: any) => {
          console.error("Error cerrarAbrirTiendas -> " + error);
          reject();
        });
    });
  }
  async crearPedido(carritoAux: any) {
    return new Promise(function (resolve, reject) {
      usuarioCalls
        .crearPedido(carritoAux)
        .then(function (result: any) {
          result.data.orders.map(function (res: any, index: any) {
            res.shop = res.shop?.gid;
            res.event = res.event?.gid;
            if (res.fk_shop != undefined) {
              res.shop = res.fk_shop;
            }
            if (res.fk_event != undefined) {
              res.event = res.fk_event;
            }
          });
          carritoAux.orders = result.data.orders;
          resolve(result);
        })
        .catch((error: any) => {
          console.log("Error crearPedido -> " + error);
          reject();
        });
    });
  }
  async crearPedidoDatafono(carritoDatafonoAux: any) {
    var t = this;
    return new Promise(function (resolve, reject) {
      console.log("usuario,crearPedidoDatafono");
      usuarioCalls
        .crearPedidoDatafono(carritoDatafonoAux)
        .then(function (result: any) {
          console.log("result", result);
          result.data.orders.map(function (res: any, index: any) {
            res.shop = res.shop?.gid;
            res.event = res.event?.gid;
            if (res.fk_shop != undefined) {
              res.shop = res.fk_shop;
            }
            if (res.fk_event != undefined) {
              res.event = res.fk_event;
            }
          });
          carritoDatafonoAux.carrito.orders = result.data.orders;
          carritoDatafonoAux.sinc = true;
          carritoDatafonoAux.actualizarCarrito();
          resolve(result);
        })
        .catch((error: any) => {
          console.log("Error crearPedidoDatafono -> " + error);
          reject();
        });
    });
  }
  comprobarPedidosDatafono() {}
  cargarCarritosDatafonoBBDD() {
    return new Promise(function (resolve, reject) {
      global.db.transaction(
        (tx: any) => {
          tx.executeSql(
            'SELECT rowid,* FROM local_orders WHERE sinc="false";',
            [],
            function (trans: any, result: any) {
              console.log("result", result);
              if (result != undefined) {
                if (result.rows.length > 0) {
                  let carritosAux = [];
                  for (var i = result.rows.length - 1; i >= 0; i--) {
                    let item = result.rows.item(i);
                    let carritoDatafono = new CarritoDatafono({
                      transactionID: item.transactionID,
                      carrito: JSON.parse(item.carrito),
                      sinc: item.sinc,
                      estado: item.estado,
                    });
                    carritosAux.push(carritoDatafono);
                  }
                  resolve(carritosAux);
                } else {
                  resolve([]);
                }
              } else {
                resolve([]);
              }
            }
          );
        },
        function (err: any) {
          console.log("err", err);
          resolve([]);
        }
      );
    });
  }
  borrarTabla() {
    return new Promise(function (resolve, reject) {
      global.db.transaction(
        (tx: any) => {
          tx.executeSql(
            "DELETE FROM local_orders",
            [],
            function (trans: any, result: any) {
              console.log("result", result);
              resolve(true);
            }
          );
        },
        function (err: any) {
          console.log("err", err);
          resolve([]);
        }
      );
    });
  }
  comprobarCarritosDatafono() {
    var thisUsuario = this;
    console.log("comprobarCarritos");
    thisUsuario.intervaloConexion = setInterval(function () {
      NetInfo.fetch().then((state) => {
        if (state.isConnected) {
          thisUsuario.cargarCarritosDatafonoBBDD().then(function (res: any) {
            if (res?.length > 0) {
              res.map(function (carritoDatafono: any, index: any) {
                console.log("carritoDatafono", carritoDatafono);
                thisUsuario.crearPedidoDatafono(carritoDatafono);
              });
            } else {
              clearInterval(thisUsuario.intervaloConexion);
            }
          });
        }
      });
    }, 300000);
  }
  async completarPedidos(ordersIs: any, status: any) {
    var t = this;
    return new Promise(function (resolve, reject) {
      usuarioCalls
        .completarPedidos(ordersIs, status)
        .then(function (result: any) {
          resolve(result);
        })
        .catch((error: any) => {
          console.log("Error completarPedidos -> " + error);
          reject();
        });
    });
  }
  cargarPedidosDatafonoBBDD() {
    return new Promise(async function (resolve, reject) {
      let ultimoCierre = await AsyncStorage.getItem("@ultimoCierre");
      global.db.transaction(
        (tx: any) => {
          tx.executeSql(
            "SELECT rowid,* FROM local_orders;",
            [],
            function (trans: any, result: any) {
              console.log("result", result);

              if (result?.rows?.length > 0) {
                let pedidosNoSinc = [];
                let pedidosSinc = [];
                let carritosAux = [];
                for (var i = result.rows.length - 1; i >= 0; i--) {
                  let item = result.rows.item(i);

                  let carrito = JSON.parse(item.carrito);

                  carritosAux.push(carrito);

                  carrito.orders.map(function (pedido: any, indice: any) {
                    pedido.payment_method = carrito.payment_method || "cash";
                    pedido.comment = carrito.comment || "";
                    pedido.sincronizado = item.sinc;
                    let pedidoAux = new Pedido(pedido);
                    console.log("fasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfa");
                    console.log("item.sincpedidoAuxpedidoAux", pedidoAux);
                    console.log("date!!!!!!!!!!!!!", pedidoAux.date);
                    if (
                      pedidoAux.date >= parseFloat(ultimoCierre) ||
                      parseFloat(ultimoCierre || "0") == 0
                    ) {
                      if (!item.sinc) {
                        pedidosNoSinc.push(pedidoAux);
                      } else {
                        pedidosSinc.push(pedidoAux);
                      }
                    }
                  });
                }
                resolve({
                  pedidosSinc: pedidosSinc,
                  pedidosNoSinc: pedidosNoSinc,
                });
              } else {
                resolve({ pedidosSinc: [], pedidosNoSinc: [] });
              }
            }
          );
        },
        function (err: any) {
          console.log("err", err);
        }
      );
    });
  }
  cargarPedidosSincronizadosBBDD() {
    return new Promise(async function (resolve, reject) {
      let ultimoCierre = await AsyncStorage.getItem("@ultimoCierre");
      global.db.transaction(
        (tx: any) => {
          tx.executeSql(
            "SELECT rowid,* FROM pedidosSincronizados;",
            [],
            function (trans: any, result: any) {
              console.log("result", result);

              if (result?.rows?.length > 0) {
                let pedidosAux = [];
                for (var i = result.rows.length - 1; i >= 0; i--) {
                  let pedido = JSON.parse(
                    result.rows.item(i).pedidosSincronizados
                  );

                  let pedidoAux = new Pedido(pedido);
                  if (
                    pedidoAux.order_date >= parseFloat(ultimoCierre) ||
                    parseFloat(ultimoCierre || "0") == 0
                  ) {
                    pedidosAux.push(pedidoAux);
                  }
                }
                resolve(pedidosAux);
              } else {
                resolve([]);
              }
            }
          );
        },
        function (err: any) {
          console.log("err", err);
        }
      );
    });
  }
  cargarPedidosBBDD() {
    return new Promise(function (resolve, reject) {
      global.db.transaction(
        (tx: any) => {
          tx.executeSql(
            "SELECT rowid,* FROM carritos;",
            [],
            function (trans: any, result: any) {
              console.log("result", result);
              if (result?.rows?.length > 0) {
                let pedidosAux = [];
                let carritosAux = [];
                for (var i = result.rows.length - 1; i >= 0; i--) {
                  let carrito = JSON.parse(result.rows.item(i).carritos);
                  carrito.note_id = result.rows.item(i).rowid;
                  carritosAux.push(carrito);
                  carrito.orders.map(function (pedido: any, indice: any) {
                    pedido.payment_method = carrito.payment_method || "cash";
                    pedido.comment = carrito.comment || "";

                    pedido.order_number =
                      pedido.id_shop +
                      "-" +
                      result.rows.item(i).rowid +
                      " (Off)";
                    pedido.note_id = result.rows.item(i).rowid;
                    pedido.sincronizado = carrito.sincronizado;
                    let pedidoAux = new Pedido(pedido);
                    pedidosAux.push(pedidoAux);
                  });
                }
                resolve(pedidosAux);
              } else {
                resolve([]);
              }
            }
          );
        },
        function (err: any) {
          console.log("err", err);
        }
      );
    });
  }
  obtenerFacturas(idPedido) {
    return new Promise(function (resolve, reject) {
      console.log("obtenerFacturas", idPedido);
      usuarioCalls
        .obtenerFacturas(idPedido)
        .then(function (result: any) {
          resolve(result);
        })
        .catch((error: any) => {
          console.log("Error completarPedidos -> " + error);
          reject();
        });
    });
  }
  async cargarTiendasStock() {
    //return await usuarioCalls.cargarTiendas(this.email, this.pass);
    var t = this;
    return new Promise(function (resolve, reject) {
      usuarioCalls
        .cargarTiendasStock(t.gid)
        .then(function (result: any) {
          console.log("result", result);
          resolve(result.data);
        })
        .catch((error: any) => {
          console.error("Error cargarTiendasStock -> " + error);
          reject();
        });
    });
  }
  async cargarPuntosDeVentaTienda(tienda: any) {
    var t = this;
    return new Promise(function (resolve, reject) {
      usuarioCalls
        .cargarPuntosDeVentaTienda(tienda.gid)
        .then(function (result: any) {
          console.log("result", result);
          resolve(result.data);
        })
        .catch((error: any) => {
          console.error("Error cargarPuntosDeVentaTienda -> " + error);
          reject();
        });
    });
  }
  async cargarProductos(idPuntoDeVenta: any) {
    var t = this;
    return new Promise(function (resolve, reject) {
      usuarioCalls
        .cargarProductos(idPuntoDeVenta)
        .then(function (result: any) {
          console.log("result", result);
          resolve(result.data);
        })
        .catch((error: any) => {
          console.error("Error cargarProductos -> " + error);
          reject();
        });
    });
  }
  async cambiarActiveProducto(idProducto: any, active: any) {
    var t = this;
    return new Promise(function (resolve, reject) {
      usuarioCalls
        .cambiarActiveProducto(idProducto, active)
        .then(function (result: any) {
          console.log("result", result);
          resolve(result.data);
        })
        .catch((error: any) => {
          console.error("Error cambiarActiveProducto -> " + error);
          reject();
        });
    });
  }
}
