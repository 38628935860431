import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import OrderResume from "./components/OrderResume";
import ProductsView from "./components/ProductsView";
import { vaciarCarrito, addProducto, setConexion } from "../../reducers";
import { connect } from "react-redux";

const FoodStallScreen = ({ navigation, route, ...props }) => {
  const tienda = route.params.tienda;
  let familiaSel = route.params.familia || "";
  const [data, setData] = useState(["hola", "adios", "hasta luego"]);
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {}, [data, open, openMenu, props.conexion]);

  useEffect(() => {
    navigation.setOptions({
      // headerTitle: tienda.name,
    });

    let dataAux: Array<any> = [];
    let dataDropdownAux: Array<any> = [];
    tienda.products.map(function (familia: any, index: number) {
      familia.products.map(function (producto: any, index: number) {
        dataAux.push(producto);
        dataDropdownAux.push({
          id: producto.gid,
          title: producto.name,
        });
      });
    });
    setData(dataAux);
  }, []);

  return (
    <View style={styles.row}>
      {global.pantallaDividida ? (
        <View style={styles.row}>
          <View style={{ width: "40%" }}>
            <OrderResume navigation={navigation} tienda={tienda} />
          </View>
          <View style={{ width: "60%" }}>
            <ProductsView
              familia={familiaSel}
              navigation={navigation}
              tienda={tienda}
            />
          </View>
        </View>
      ) : (
        <View style={styles.row}>
          <ProductsView
            familia={familiaSel}
            navigation={navigation}
            tienda={tienda}
          />
        </View>
      )}
    </View>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tiendas: state.carrito.tiendas,
    width: state.dimensions.width,
    conexion: state.dimensions.conexion,
  };
};
const mapDispatchToProps = {
  vaciarCarrito,
  addProducto,
  setConexion,
};

export default connect(mapStateToProps, mapDispatchToProps)(FoodStallScreen);

const styles = StyleSheet.create({
  row: { flex: 1, flexDirection: "row" },
});
