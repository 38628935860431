import { useTranslation } from "react-i18next";

export function getIngredientsText(ingredients: any) {
  const {t} = useTranslation()
  let srtIngredientsAux = "";
  let primerIng = true;
  if (ingredients != undefined) {
    ingredients.map(function (ingredient: any) {
      var strComa = ", ";
      if (primerIng) {
        strComa = "";
        primerIng = false;
      }
      srtIngredientsAux += strComa + t(ingredient.nombre);
    });
  }

  return srtIngredientsAux;
}
