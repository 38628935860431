import React, { useState } from "react";
import { View, StyleSheet, TouchableOpacity, Text } from "react-native";

// Components
import Divider from "../Divider";
import LanguageModal from "../modals/LanguageModal";

// i18n
import { languageNames } from "../../../../i18n/languageNames";

// Context
import { useLanguage } from "../../../../contexts/LanguageContext";

// Methods
import { getFlagIcon } from "./methods/getFlagIcon";

const LanguageSelector = () => {
  const [modal, setModal] = useState<String>();

  const { language } = useLanguage();

  return (
    <View>
      <TouchableOpacity onPress={() => setModal("Language")}>
        <View style={styles.headContent}>
          {getFlagIcon(language)}
          <Divider width={8} />
          <View style={styles.titleContainer}>
            <Text style={styles.title}>
              {languageNames[language].substring(0, 3)}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
      <LanguageModal visible={modal === "Language"} setModal={setModal} />
    </View>
  );
};

export default LanguageSelector;

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    color: "black",
    lineHeight: 21
  },
  headContent: {
    flexDirection: "row",
    borderRadius: 40,
    paddingRight: 10,
    height: 36,
    width: "100%",
    borderColor: "grey",
    alignItems: "center",
    paddingHorizontal: 5,
    borderWidth: 1,
  },

  itemContainer: {
    width: "100%",
  },
  titleContainer: {
    width: 35,
  },
  item: {
    flexDirection: "row",
    width: "100%",
    paddingHorizontal: 6,
    alignItems: "center",
  },
  itemLabel: {},
  bodyBackground: {
    overflow: "hidden",
    width: "100%",
    position: "absolute",
    top: 36,
  },
  bodyContainer: {
    paddingTop: 12,
    width: "100%",
    backgroundColor: "white",
  },
});
